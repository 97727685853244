const activity = {
    "social exchanges": [
        { translate: "good morning", key: "good morning" },
        { translate: "good afternoon", key: "good afternoon" },
        { translate: "good evening", key: "good evening" },
        { translate: "good night", key: "good night" },
        { translate: "good bye", key: "good bye" },
        { translate: "please", key: "please" },
        { translate: "thank you", key: "thank you" },
        { translate: "my name is", key: "my name is" },
        { translate: "Where do you come from", key: "where do you come from" },
        { translate: "I come from Moscow", key: "i come from moscow" },
        { translate: "I am going to South Africa", key: "i am going to south africa" },
        { translate: "What is your name", key: "what is your name" },
        { translate: "How is it going", key: "how is it going" },
        { translate: "Very well thanks, and yourself", key: "very well thanks and yourself" },
        { translate: "Not too bad thanks", key: "not too bad thanks" },
        { translate: "Pardon me please", key: "pardon me please" },
        { translate: "How does one say", key: "how does one say" },
        { translate: "May I speak English please", key: "may i speak english please" },
        { translate: "You are welcome", key: "you are welcome" },
        { translate: "I am sorry", key: "i am sorry" },
        { translate: "Do you know each other", key: "do you know each other" },
        { translate: "it is lovely weather", key: "it is lovely weather" },
        { translate: "do not yawn like that", key: "do not yawn like that" },
        { translate: "Sleep well", key: "sleep well" },
        { translate: "Congratulations", key: "congratulations" },
        { translate: "good luck", key: "good luck" },
    ],
    "class phrases": [
        { translate: "Listen carefully", key: "listen carefully" },
        { translate: "Repeat please", key: "repeat please" },
        { translate: "nearly", key: "nearly" },
        { translate: "Try again", key: "try again" },
        { translate: "Say it again please", key: "say it again please" },
        { translate: "Answer me", key: "answer me" },
        { translate: "Do you understand", key: "do you understand" },
        { translate: "pronunciation", key: "pronunciation" },
        { translate: "Ask him or her if", key: "ask him or her if" },
        { translate: "What is the difference between", key: "what is the difference between" },
        { translate: "I do not know", key: "i do not know" },
        { translate: "I do not understand", key: "i do not understand" },
        { translate: "Please speak slower", key: "please speak slower" },
        { translate: "What does it mean", key: "what does it mean" },
        { translate: "I have a question", key: "i have a question" },
        { translate: "Past", key: "past" },
        { translate: "Present", key: "present" },
        { translate: "Future", key: "future" },
    ],
    "color": [
        { translate: "white", key: "white" },
        { translate: "yellow", key: "yellow" },
        { translate: "orange", key: "orange" },
        { translate: "red", key: "red" },
        { translate: "green", key: "green" },
        { translate: "blue", key: "blue" },
        { translate: "purple", key: "purple" },
        { translate: "light gray", key: "light gray" },
        { translate: "dark gray", key: "dark gray" },
        { translate: "brown", key: "brown" },
        { translate: "black", key: "black" },
        { translate: "khaki", key: "khaki" },
    ],
    "wind directions": [
        { translate: "south", key: "south" },
        { translate: "east", key: "east" },
        { translate: "north", key: "north" },
        { translate: "west", key: "west" },
        { translate: "north west", key: "north west" },
        { translate: "north east", key: "north east" },
        { translate: "south west", key: "south west" },
        { translate: "south east", key: "south east" },
    ],
    "seasons": [
        { translate: "seasons", key: "seasons" },
        { translate: "winter", key: "winter" },
        { translate: "spring", key: "spring" },
        { translate: "summer", key: "summer" },
        { translate: "fall", key: "fall" },
    ],
    "months": [
        { translate: "January", key: "january" },
        { translate: "February", key: "february" },
        { translate: "March", key: "march" },
        { translate: "April", key: "april" },
        { translate: "May", key: "may" },
        { translate: "June", key: "june" },
        { translate: "July", key: "july" },
        { translate: "August", key: "august" },
        { translate: "September", key: "september" },
        { translate: "October", key: "october" },
        { translate: "November", key: "november" },
        { translate: "December", key: "december" },
    ], 
    "days of the week": [
        { translate: "Monday", key: "monday" },
        { translate: "Tuesday", key: "tuesday" },
        { translate: "Wednesday", key: "wednesday" },
        { translate: "Thursday", key: "thursday" },
        { translate: "Friday", key: "friday" },
        { translate: "Saturday", key: "saturday" },
        { translate: "Sunday", key: "sunday" },
        { translate: "the weekend", key: "the weekend" },
    ],
    "periods of time": [
        { translate: "millennium", key: "millennium" },
        { translate: "century", key: "century" },
        { translate: "decade", key: "decade" },
        { translate: "year", key: "year" },
        { translate: "month", key: "month" },
        { translate: "week", key: "week" },
        { translate: "day", key: "day" },
        { translate: "hour", key: "hour" },
        { translate: "minute", key: "minute" },
        { translate: "second", key: "second" },
        { translate: "a while", key: "a while" },
        { translate: "forever", key: "forever" },
        { translate: "temporary", key: "temporary" },
        { translate: "permanent", key: "permanent" },
        { translate: "now", key: "now" },
        { translate: "in a while", key: "in a while" },
        { translate: "sometime later", key: "sometime later" },
        { translate: "earlier", key: "earlier" },
        { translate: "never", key: "never" },
        { translate: "suddenly", key: "suddenly" },
    ],
    "cycles of time": [
        { translate: "every five seconds", key: "every five seconds" },
        { translate: "every minute", key: "every minute" },
        { translate: "every half hour", key: "every half hour" },
        { translate: "every day", key: "every day" },
        { translate: "bi weekly", key: "bi weekly" },
        { translate: "hourly", key: "hourly" },
        { translate: "daily", key: "daily" },
        { translate: "weekly", key: "weekly" },
        { translate: "monthly", key: "monthly" },
        { translate: "bi monthly", key: "bi monthly" },
        { translate: "biannually", key: "biannually" },
        { translate: "yearly", key: "yearly" },
    ],
    "time of the day": [
        { translate: "early in the morning", key: "early in the morning" },
        { translate: "late in the afternoon", key: "late in the afternoon" },
        { translate: "just before sunrise", key: "just before sunrise" },
        { translate: "just after sunset", key: "just after sunset" },
        { translate: "at midnight", key: "at midnight" },
        { translate: "at noon", key: "at noon" },
        { translate: "day before yesterday", key: "day before yesterday" },
        { translate: "yesterday", key: "yesterday" },
        { translate: "today", key: "today" },
        { translate: "tomorrow", key: "tomorrow" },
        { translate: "day after tomorrow", key: "day after tomorrow" },
        { translate: "this morning", key: "this morning" },
        { translate: "this afternoon", key: "this afternoon" },
        { translate: "this evening", key: "this evening" },
        { translate: "tonight", key: "tonight" },
        { translate: "in the mornings", key: "in the mornings" },
        { translate: "in the afternoons", key: "in the afternoons" },
        { translate: "in the evenings", key: "in the evenings" },
        { translate: "at night", key: "at night" },
        { translate: "during the day", key: "during the day" },
    ],
    "numbers one": [
        { translate: "zero", key: "zero" },
        { translate: "one", key: "one" },
        { translate: "two", key: "two" },
        { translate: "three", key: "three" },
        { translate: "four", key: "four" },
        { translate: "five", key: "five" },
        { translate: "six", key: "six" },
        { translate: "seven", key: "seven" },
        { translate: "eight", key: "eight" },
        { translate: "nine", key: "nine" },
        { translate: "ten", key: "ten" },
        { translate: "eleven", key: "eleven" },
        { translate: "twelve", key: "twelve" },
        { translate: "thirteen", key: "thirteen" },
        { translate: "fourteen", key: "fourteen" },
        { translate: "fifteen", key: "fifteen" },
        { translate: "sixteen", key: "sixteen" },
        { translate: "seventeen", key: "seventeen" },
        { translate: "eighteen", key: "eighteen" },
        { translate: "nineteen", key: "nineteen" },
        { translate: "twenty", key: "twenty" },
    ],
    "numbers two": [
        { translate: "twenty one", key: "twenty one" },
        { translate: "twenty two", key: "twenty two" },
        { translate: "twenty three", key: "twenty three" },
        { translate: "twenty four", key: "twenty four" },
        { translate: "twenty five", key: "twenty five" },
        { translate: "twenty six", key: "twenty six" },
        { translate: "twenty seven", key: "twenty seven" },
        { translate: "twenty eight", key: "twenty eight" },
        { translate: "twenty nine", key: "twenty nine" },
        { translate: "thirty", key: "thirty" },
        { translate: "forty", key: "forty" },
        { translate: "fifty", key: "fifty" },
        { translate: "sixty", key: "sixty" },
        { translate: "seventy", key: "seventy" },
        { translate: "eighty", key: "eighty" },
        { translate: "ninety", key: "ninety" },
        { translate: "hundred", key: "hundred" },
        { translate: "thousand", key: "thousand" },
        { translate: "million", key: "million" },
        { translate: "billion", key: "billion" },
        { translate: "trillion", key: "trillion" },
    ],
    "numbers three": [
        { translate: "first", key: "first" },
        { translate: "firstly", key: "firstly" },
        { translate: "second", key: "second" },
        { translate: "secondly", key: "secondly" },
        { translate: "third", key: "third" },
        { translate: "thirdly", key: "thirdly" },
        { translate: "fourth", key: "fourth" },
        { translate: "fourthly", key: "fourthly" },
        { translate: "a half", key: "a half" },
        { translate: "a third", key: "a third" },
        { translate: "a quarter", key: "a quarter" },
        { translate: "three quarters", key: "three quarters" },
        { translate: "a tenth", key: "a tenth" },
        { translate: "an eighth", key: "an eighth" },
        { translate: "a fifth", key: "a fifth" },
    ],
    "time": [
        { translate: "What is the time", key: "what is the time" },
        { translate: "It is ", key: "it is " },
        { translate: "one oclock", key: "one oclock" },
        { translate: "three oclock", key: "three oclock" },
        { translate: "quarter past two", key: "quarter past two" },
        { translate: "half past two", key: "half past two" },
        { translate: "quarter to four", key: "quarter to four" },
        { translate: "ten to six", key: "ten to six" },
        { translate: "twenty five past seven", key: "twenty five past seven" },
        { translate: "five to nine", key: "five to nine" },
        { translate: "twenty to one", key: "twenty to one" },
        { translate: "three twenty five", key: "three twenty five" },
        { translate: "four twenty nine", key: "four twenty nine" },
        { translate: "six thirty one", key: "six thirty one" },
        { translate: "seven forty five", key: "seven forty five" },
        { translate: "eight fifty three", key: "eight fifty three" },
        { translate: "nine fifty one", key: "nine fifty one" },
        { translate: "five twenty eight", key: "five twenty eight" },
    ],
    "family": [
        { translate: "great grandfather", key: "great grandfather" },
        { translate: "great grandmother", key: "great grandmother" },
        { translate: "grandfather", key: "grandfather" },
        { translate: "grandmother", key: "grandmother" },
        { translate: "mother", key: "mother" },
        { translate: "father", key: "father" },
        { translate: "brothers", key: "brothers" },
        { translate: "sisters", key: "sisters" },
        { translate: "uncle", key: "uncle" },
        { translate: "aunt", key: "aunt" },
        { translate: "male cousin", key: "male cousin" },
        { translate: "male cousin", key: "male cousin" },
        { translate: "cousins", key: "cousins" },
        { translate: "family in law", key: "family in law" },
        { translate: "mother in law", key: "mother in law" },
        { translate: "father in law", key: "father in law" },
        { translate: "sister in law", key: "sister in law" },
        { translate: "brother in law", key: "brother in law" },
        { translate: "grandchildren", key: "grandchildren" },
        { translate: "granddaughter", key: "granddaughter" },
        { translate: "grandson", key: "grandson" },
        { translate: "nephew", key: "nephew" },
        { translate: "niece", key: "niece" },
        { translate: "twins", key: "twins" },
        { translate: "triplets", key: "triplets" },
        { translate: "parents", key: "parents" },
        { translate: "spouses", key: "spouses" },
        { translate: "wife", key: "wife" },
        { translate: "husband", key: "husband" },
        { translate: "family", key: "family" },
    ],
    "meals": [
        { translate: "breakfast", key: "breakfast" },
        { translate: "lunch", key: "lunch" },
        { translate: "supper", key: "supper" },
        { translate: "hors doeuvres", key: "hors doeuvres" },
        { translate: "entree", key: "entree" },
        { translate: "dessert", key: "dessert" },
        { translate: "buffet", key: "buffet" },
        { translate: "snacks", key: "snacks" },
    ],
    "shapes": [
        { translate: "three point star", key: "three point star" },
        { translate: "four point star", key: "four point star" },
        { translate: "five point star", key: "five point star" },
        { translate: "six point star", key: "six point star" },
        { translate: "rectangle", key: "rectangle" },
        { translate: "parallelogram", key: "parallelogram" },
        { translate: "trapezoid", key: "trapezoid" },
        { translate: "circle", key: "circle" },
        { translate: "oval", key: "oval" },
        { translate: "triangle", key: "triangle" },
        { translate: "square", key: "square" },
        { translate: "pentagon", key: "pentagon" },
        { translate: "hexagon", key: "hexagon" },
        { translate: "octagon", key: "octagon" },
        { translate: "rhombus", key: "rhombus" },
        { translate: "sphere", key: "sphere" },
        { translate: "cone", key: "cone" },
        { translate: "cube", key: "cube" },
        { translate: "cylinder", key: "cylinder" },
        { translate: "spiral", key: "spiral" },
        { translate: "line", key: "line" },
        { translate: "angle or corner", key: "angle or corner" },
        { translate: "side", key: "side" },
    ],
    "opposites": [
        { translate: "slow", key: "slow" },
        { translate: "fast", key: "fast" },
        { translate: "strong", key: "strong" },
        { translate: "weak", key: "weak" },
        { translate: "long", key: "long" },
        { translate: "short", key: "short" },
        { translate: "difficult", key: "difficult" },
        { translate: "easy", key: "easy" },
        { translate: "good", key: "good" },
        { translate: "bad", key: "bad" },
        { translate: "near", key: "near" },
        { translate: "far", key: "far" },
        { translate: "few", key: "few" },
        { translate: "plenty", key: "plenty" },
        { translate: "a little bit", key: "a little bit" },
        { translate: "much", key: "much" },
        { translate: "shallow", key: "shallow" },
        { translate: "deep", key: "deep" },
        { translate: "big", key: "big" },
        { translate: "small", key: "small" },
        { translate: "high", key: "high" },
        { translate: "low", key: "low" },
        { translate: "beautiful", key: "beautiful" },
        { translate: "ugly", key: "ugly" },
        { translate: "straight", key: "straight" },
        { translate: "crooked", key: "crooked" },
        { translate: "narrow", key: "narrow" },
        { translate: "wide", key: "wide" },
        { translate: "full", key: "full" },
        { translate: "empty", key: "empty" },
        { translate: "hot", key: "hot" },
        { translate: "cold", key: "cold" },
        { translate: "hard", key: "hard" },
        { translate: "soft", key: "soft" },
        { translate: "tasty", key: "tasty" },
        { translate: "bad tasting", key: "bad tasting" },
        { translate: "sweet", key: "sweet" },
        { translate: "bitter", key: "bitter" },
        { translate: "hard working", key: "hard working" },
        { translate: "lazy", key: "lazy" },
        { translate: "well behaved", key: "well behaved" },
        { translate: "naughty", key: "naughty" },
        { translate: "skinny", key: "skinny" },
        { translate: "fat", key: "fat" },
        { translate: "white", key: "white" },
        { translate: "on", key: "on" },
        { translate: "off", key: "off" },
        { translate: "in front of", key: "in front of" },
        { translate: "no", key: "no" },
        { translate: "here", key: "here" },
        { translate: "never", key: "never" },
    ],
    "degrees of comparison": [
        { translate: "slow", key: "slow" },
        { translate: "faster", key: "faster" },
        { translate: "strongest", key: "strongest" },
        { translate: "weakest", key: "weakest" },
        { translate: "long", key: "long" },
        { translate: "shortest", key: "shortest" },
        { translate: "more difficult", key: "more difficult" },
        { translate: "easiest", key: "easiest" },
        { translate: "better", key: "better" },
        { translate: "worst", key: "worst" },
        { translate: "close", key: "close" },
        { translate: "far", key: "far" },
        { translate: "least", key: "least" },
        { translate: "much", key: "much" },
        { translate: "shallow", key: "shallow" },
        { translate: "deepest", key: "deepest" },
        { translate: "biggest", key: "biggest" },
        { translate: "smallest", key: "smallest" },
        { translate: "highest", key: "highest" },
        { translate: "lower", key: "lower" },
        { translate: "skinnier", key: "skinnier" },
        { translate: "fattest", key: "fattest" },
        { translate: "prettier", key: "prettier" },
        { translate: "uglier", key: "uglier" },
        { translate: "warmest", key: "warmest" },
        { translate: "colder", key: "colder" },
        { translate: "hardest", key: "hardest" },
        { translate: "softer", key: "softer" },
        { translate: "tasty", key: "tasty" },
        { translate: "bad", key: "bad" },
        { translate: "more satisfied", key: "more satisfied" },
        { translate: "most correct", key: "most correct" },
        { translate: "cheapest", key: "cheapest" },
        { translate: "most expensive", key: "most expensive" },
    ],
    "feelings": [
        { translate: "joy", key: "joy" },
        { translate: "unsatisfied", key: "unsatisfied" },
        { translate: "confused", key: "confused" },
        { translate: "unsure", key: "unsure" },
        { translate: "angry", key: "angry" },
        { translate: "scared", key: "scared" },
        { translate: "embarrassed", key: "embarrassed" },
        { translate: "frustrated", key: "frustrated" },
        { translate: "bored", key: "bored" },
        { translate: "ashamed", key: "ashamed" },
        { translate: "annoyed", key: "annoyed" },
        { translate: "concerned", key: "concerned" },
        { translate: "love", key: "love" },
        { translate: "hate", key: "hate" },
        { translate: "to trust", key: "to trust" },
        { translate: "to distrust", key: "to distrust" },
        { translate: "to cry", key: "to cry" },
        { translate: "to laugh", key: "to laugh" },
        { translate: "to smile", key: "to smile" },
        { translate: "to think", key: "to think" },
        { translate: "ponder", key: "ponder" },
        { translate: "to gossip", key: "to gossip" },
        { translate: "to hope", key: "to hope" },
        { translate: "to long for", key: "to long for" },
        { translate: "kind", key: "kind" },
        { translate: "excited", key: "excited" },
        { translate: "sad", key: "sad" },
        { translate: "sorrowful", key: "sorrowful" },
        { translate: "shy", key: "shy" },
        { translate: "nervous", key: "nervous" },
        { translate: "depressed", key: "depressed" },
        { translate: "to complain", key: "to complain" },
        { translate: "to tremble", key: "to tremble" },
        { translate: "to sweat", key: "to sweat" },
        { translate: "to blush", key: "to blush" },
        { translate: "to chatter", key: "to chatter" },
        { translate: "to giggle", key: "to giggle" },
        { translate: "to frown", key: "to frown" },
        { translate: "enthusiastic", key: "enthusiastic" },
    ],
    "moods": [
        { translate: "aggressive", key: "aggressive" },
        { translate: "chipper or in good spirit is", key: "chipper or in good spirit is" },
        { translate: "contemplative", key: "contemplative" },
        { translate: "content", key: "content" },
        { translate: "cheerful", key: "cheerful" },
        { translate: "depressed", key: "depressed" },
        { translate: "happy", key: "happy" },
        { translate: "lazy", key: "lazy" },
        { translate: "moody", key: "moody" },
        { translate: "naughty", key: "naughty" },
        { translate: "nostalgic", key: "nostalgic" },
        { translate: "suspicious", key: "suspicious" },
        { translate: "patient", key: "patient" },
        { translate: "impatient", key: "impatient" },
        { translate: "sleepy", key: "sleepy" },
        { translate: "weird", key: "weird" },
    ],
    "character traits": [
        { translate: "loving", key: "loving" },
        { translate: "unloving", key: "unloving" },
        { translate: "friendly", key: "friendly" },
        { translate: "gentle", key: "gentle" },
        { translate: "sensitive", key: "sensitive" },
        { translate: "coarse", key: "coarse" },
        { translate: "insensitive", key: "insensitive" },
        { translate: "honest", key: "honest" },
        { translate: "dishonest", key: "dishonest" },
        { translate: "humble", key: "humble" },
        { translate: "proud", key: "proud" },
        { translate: "unfriendly", key: "unfriendly" },
        { translate: "courageous", key: "courageous" },
        { translate: "cowardly", key: "cowardly" },
        { translate: "jealous", key: "jealous" },
        { translate: "diligent", key: "diligent" },
        { translate: "enthusiastic", key: "enthusiastic" },
        { translate: "shy", key: "shy" },
        { translate: "good", key: "good" },
        { translate: "evil", key: "evil" },
        { translate: "stubborn", key: "stubborn" },
        { translate: "smart", key: "smart" },
        { translate: "dumb", key: "dumb" },
        { translate: "sympathetic", key: "sympathetic" },
        { translate: "honorable", key: "honorable" },
        { translate: "giving", key: "giving" },
        { translate: "apathetic", key: "apathetic" },
        { translate: "extroverted", key: "extroverted" },
        { translate: "introverted", key: "introverted" },
        { translate: "unsympathetic", key: "unsympathetic" },
        { translate: "serious", key: "serious" },
        { translate: "frivolous", key: "frivolous" },
    ],
    "taste": [
        { translate: "sweet", key: "sweet" },
        { translate: "bitter", key: "bitter" },
        { translate: "sour", key: "sour" },
        { translate: "salty", key: "salty" },
        { translate: "flavorful", key: "flavorful" },
        { translate: "hot", key: "hot" },
    ],
    "adjectives one": [
        { translate: "broken", key: "broken" },
        { translate: "fixed", key: "fixed" },
        { translate: "brave", key: "brave" },
        { translate: "cowardly", key: "cowardly" },
        { translate: "cheap", key: "cheap" },
        { translate: "expensive", key: "expensive" },
        { translate: "clean", key: "clean" },
        { translate: "dirty", key: "dirty" },
        { translate: "heavy", key: "heavy" },
        { translate: "light", key: "light" },
        { translate: "hungry", key: "hungry" },
        { translate: "satisfied", key: "satisfied" },
        { translate: "last", key: "last" },
        { translate: "first", key: "first" },
        { translate: "loud", key: "loud" },
        { translate: "soft", key: "soft" },
        { translate: "lucky", key: "lucky" },
        { translate: "unlucky", key: "unlucky" },
        { translate: "modern", key: "modern" },
        { translate: "old", key: "old" },
        { translate: "naughty", key: "naughty" },
        { translate: "well behaved", key: "well behaved" },
        { translate: "normal", key: "normal" },
        { translate: "abnormal", key: "abnormal" },
        { translate: "obedient", key: "obedient" },
        { translate: "disobedient", key: "disobedient" },
    ],
    "adjectives two": [
        { translate: "new", key: "new" },
        { translate: "open", key: "open" },
        { translate: "closed", key: "closed" },
        { translate: "green", key: "green" },
        { translate: "ripe", key: "ripe" },
        { translate: "rotten", key: "rotten" },
        { translate: "rude", key: "rude" },
        { translate: "polite", key: "polite" },
        { translate: "sore", key: "sore" },
        { translate: "healthy", key: "healthy" },
        { translate: "strong", key: "strong" },
        { translate: "weak", key: "weak" },
        { translate: "thankful", key: "thankful" },
        { translate: "ungrateful", key: "ungrateful" },
        { translate: "thirsty", key: "thirsty" },
        { translate: "tidy", key: "tidy" },
        { translate: "untidy", key: "untidy" },
        { translate: "tight", key: "tight" },
        { translate: "loose", key: "loose" },
        { translate: "warm or hot", key: "warm or hot" },
        { translate: "cold", key: "cold" },
        { translate: "wet", key: "wet" },
        { translate: "dry", key: "dry" },
        { translate: "worried", key: "worried" },
        { translate: "calm", key: "calm" },
        { translate: "young", key: "young" },
        { translate: "old", key: "old" },
    ],
    "adverbs": [
        { translate: "quickly", key: "quickly" },
        { translate: "fast", key: "fast" },
        { translate: "quietly", key: "quietly" },
        { translate: "well", key: "well" },
        { translate: "correctly", key: "correctly" },
        { translate: "here", key: "here" },
        { translate: "there", key: "there" },
        { translate: "somewhere", key: "somewhere" },
        { translate: "afterwards", key: "afterwards" },
        { translate: "never", key: "never" },
        { translate: "soon", key: "soon" },
        { translate: "daily", key: "daily" },
        { translate: "now", key: "now" },
        { translate: "next", key: "next" },
        { translate: "always", key: "always" },
        { translate: "often", key: "often" },
        { translate: "sometimes", key: "sometimes" },
        { translate: "almost", key: "almost" },
        { translate: "very", key: "very" },
        { translate: "too", key: "too" },
        { translate: "easily", key: "easily" },
        { translate: "suddenly", key: "suddenly" },
        { translate: "far", key: "far" },
        { translate: "exactly", key: "exactly" },
        { translate: "less", key: "less" },
    ],
    "upper body external": [
        { translate: "head", key: "head" },
        { translate: "cheeks", key: "cheeks" },
        { translate: "chin", key: "chin" },
        { translate: "jaw", key: "jaw" },
        { translate: "voice", key: "voice" },
        { translate: "hair", key: "hair" },
        { translate: "a hair", key: "a hair" },
        { translate: "face", key: "face" },
        { translate: "ear", key: "ear" },
        { translate: "eye", key: "eye" },
        { translate: "eyes", key: "eyes" },
        { translate: "nose", key: "nose" },
        { translate: "mouth", key: "mouth" },
        { translate: "lips", key: "lips" },
        { translate: "throat", key: "throat" },
        { translate: "skin", key: "skin" },
        { translate: "neck", key: "neck" },
        { translate: "shoulder", key: "shoulder" },
        { translate: "chest", key: "chest" },
    ],
    "lower body external": [
        { translate: "back", key: "back" },
        { translate: "upper arm", key: "upper arm" },
        { translate: "elbow", key: "elbow" },
        { translate: "elbows", key: "elbows" },
        { translate: "forearm", key: "forearm" },
        { translate: "hands", key: "hands" },
        { translate: "fingers", key: "fingers" },
        { translate: "thumb", key: "thumb" },
        { translate: "wrists or joints", key: "wrists or joints" },
        { translate: "pulse", key: "pulse" },
        { translate: "index finger", key: "index finger" },
        { translate: "pinkie", key: "pinkie" },
        { translate: "buttocks", key: "buttocks" },
        { translate: "legs", key: "legs" },
        { translate: "knees", key: "knees" },
        { translate: "calf", key: "calf" },
        { translate: "shin", key: "shin" },
        { translate: "ankle", key: "ankle" },
        { translate: "foot or feet", key: "foot or feet" },
        { translate: "heel", key: "heel" },
        { translate: "sole", key: "sole" },
        { translate: "toes", key: "toes" },
    ],
    "body internal": [
        { translate: "teeth", key: "teeth" },
        { translate: "tongue", key: "tongue" },
        { translate: "ribs", key: "ribs" },
        { translate: "stomach", key: "stomach" },
        { translate: "brain", key: "brain" },
        { translate: "heart", key: "heart" },
        { translate: "kidneys", key: "kidneys" },
        { translate: "lungs", key: "lungs" },
        { translate: "veins", key: "veins" },
        { translate: "arteries", key: "arteries" },
        { translate: "nerves, sinews", key: "nerves, sinews" },
        { translate: "bones", key: "bones" },
        { translate: "muscles", key: "muscles" },
        { translate: "organs", key: "organs" },
        { translate: "spleen", key: "spleen" },
        { translate: "liver", key: "liver" },
        { translate: "gallbladder", key: "gallbladder" },
    ],
    "exercises": [
        { translate: "to do push ups", key: "to do push ups" },
        { translate: "to do sit ups", key: "to do sit ups" },
        { translate: "to do pull ups", key: "to do pull ups" },
        { translate: "to lift weights", key: "to lift weights" },
        { translate: "to stretch", key: "to stretch" },
        { translate: "to walk", key: "to walk" },
        { translate: "to jog", key: "to jog" },
        { translate: "to run", key: "to run" },
        { translate: "to swim laps", key: "to swim laps" },
        { translate: "to lose weight", key: "to lose weight" },
        { translate: "to gain weight", key: "to gain weight" },
        { translate: "to be fit", key: "to be fit" },
        { translate: "to be out of breath", key: "to be out of breath" },
        { translate: "heart rate", key: "heart rate" },
        { translate: "tired or exhausted", key: "tired or exhausted" },
    ],
    "clothing general": [
        { translate: "pants or slacks", key: "pants or slacks" },
        { translate: "shirt", key: "shirt" },
        { translate: "pocket", key: "pocket" },
        { translate: "belt", key: "belt" },
        { translate: "socks", key: "socks" },
        { translate: "socks", key: "socks" },
        { translate: "shoes", key: "shoes" },
        { translate: "sleeve", key: "sleeve" },
        { translate: "button or knot", key: "button or knot" },
        { translate: "zipper", key: "zipper" },
        { translate: "glasses", key: "glasses" },
        { translate: "pajamas", key: "pajamas" },
        { translate: "rain coat", key: "rain coat" },
        { translate: "jersey or sweater", key: "jersey or sweater" },
        { translate: "gloves", key: "gloves" },
        { translate: "scarf", key: "scarf" },
        { translate: "slippers", key: "slippers" },
        { translate: "umbrella", key: "umbrella" },
        { translate: "sandals", key: "sandals" },
        { translate: "flip flops", key: "flip flops" },
        { translate: "robe", key: "robe" },
        { translate: "sunglasses", key: "sunglasses" },
        { translate: "underwear", key: "underwear" },
    ],
    "clothing mens": [
        { translate: "tie", key: "tie" },
        { translate: "blazer or jacket", key: "blazer or jacket" },
        { translate: "suit", key: "suit" },
        { translate: "suede shoes", key: "suede shoes" },
        { translate: "collar", key: "collar" },
        { translate: "vest or waist coat", key: "vest or waist coat" },
    ],
    "clothing womens": [
        { translate: "dress", key: "dress" },
        { translate: "blouse", key: "blouse" },
        { translate: "skirt", key: "skirt" },
        { translate: "high heels", key: "high heels" },
        { translate: "swim suit", key: "swim suit" },
        { translate: "nylons", key: "nylons" },
    ],
    "clothing fabrics": [
        { translate: "wool", key: "wool" },
        { translate: "cotton", key: "cotton" },
        { translate: "leather", key: "leather" },
        { translate: "silk", key: "silk" },
        { translate: "linen", key: "linen" },
    ],
    "describe age": [
        { translate: "age", key: "age" },
        { translate: "52 years old", key: "52 years old" },
        { translate: "old", key: "old" },
        { translate: "young", key: "young" },
        { translate: "middle aged", key: "middle aged" },
        { translate: "a teenager", key: "a teenager" },
        { translate: "a senior citizen", key: "a senior citizen" },
        { translate: "an infant", key: "an infant" },
    ],
    "describe gender and race": [
        { translate: "gender", key: "gender" },
        { translate: "male", key: "male" },
        { translate: "female", key: "female" },
        { translate: "race", key: "race" },
        { translate: "East Asian", key: "east asian" },
        { translate: "South Asian", key: "south asian" },
        { translate: "Pacific Islander", key: "pacific islander" },
        { translate: "African", key: "African" },
        { translate: "Mediterranean", key: "mediterranean" },
        { translate: "North European", key: "north european" },
        { translate: "Latina", key: "Latina" },
        { translate: "American Indian", key: "american indian" },
    ],
    "describe weight and height": [
        { translate: "weight", key: "weight" },
        { translate: "He weighs about 67 kg or lb", key: "he weighs about 67 kg or lb" },
        { translate: "skinny", key: "skinny" },
        { translate: "fat", key: "fat" },
        { translate: "slender", key: "slender" },
        { translate: "small", key: "small" },
        { translate: "big", key: "big" },
        { translate: "heavy", key: "heavy" },
        { translate: "light", key: "light" },
        { translate: "obese", key: "obese" },
        { translate: "really fat", key: "really fat" },
        { translate: "overweight", key: "overweight" },
        { translate: "height", key: "height" },
        { translate: "She is 1,6 meters tall", key: "she is 1 6 meters tall" },
        { translate: "short", key: "short" },
        { translate: "tall", key: "tall" },
    ],
    "describe hair": [
        { translate: "hair", key: "hair" },
        { translate: "blond", key: "blond" },
        { translate: "gray", key: "gray" },
        { translate: "white", key: "white" },
        { translate: "brown", key: "brown" },
        { translate: "red", key: "red" },
        { translate: "black", key: "black" },
        { translate: "colored", key: "colored" },
        { translate: "long", key: "long" },
        { translate: "short", key: "short" },
        { translate: "shoulder length", key: "shoulder length" },
        { translate: "straight", key: "straight" },
        { translate: "wavy", key: "wavy" },
        { translate: "curly", key: "curly" },
        { translate: "bold", key: "bold" },
        { translate: "uncombed", key: "uncombed" },
        { translate: "greasy", key: "greasy" },
        { translate: "dyed blue", key: "dyed blue" },
    ],
    "describe eyes and skin": [
        { translate: "eyes", key: "eyes" },
        { translate: "brown", key: "brown" },
        { translate: "hazel", key: "hazel" },
        { translate: "blue", key: "blue" },
        { translate: "green", key: "green" },
        { translate: "gray", key: "gray" },
        { translate: "black", key: "black" },
        { translate: "slanted", key: "slanted" },
        { translate: "skin", key: "skin" },
        { translate: "dark", key: "dark" },
        { translate: "light", key: "light" },
        { translate: "reddish", key: "reddish" },
        { translate: "olive", key: "olive" },
        { translate: "freckles", key: "freckles" },
        { translate: "pimples", key: "pimples" },
        { translate: "pock marks", key: "pock marks" },
        { translate: "scar", key: "scar" },
        { translate: "marks", key: "marks" },
        { translate: "tattoos", key: "tattoos" },
        { translate: "beard", key: "beard" },
        { translate: "moustache", key: "moustache" },
        { translate: "moles", key: "moles" },
        { translate: "hairy", key: "hairy" },
    ],
    "general physical descriptions": [
        { translate: "metal frame glasses", key: "metal frame glasses" },
        { translate: "plastic frame glasses", key: "plastic frame glasses" },
        { translate: "frameless glasses", key: "frameless glasses" },
        { translate: "accent", key: "accent" },
        { translate: "mannerisms", key: "mannerisms" },
        { translate: "body language", key: "body language" },
        { translate: "walk", key: "walk" },
        { translate: "athletic", key: "athletic" },
        { translate: "non athletic", key: "non athletic" },
        { translate: "gorgeous", key: "gorgeous" },
        { translate: "beautiful", key: "beautiful" },
        { translate: "handsome", key: "handsome" },
        { translate: "unshaven", key: "unshaven" },
    ],
    "general food": [
        { translate: "yogurt", key: "yogurt" },
        { translate: "eggs", key: "eggs" },
        { translate: "butter", key: "butter" },
        { translate: "cheese", key: "cheese" },
        { translate: "toast", key: "toast" },
        { translate: "jam", key: "jam" },
        { translate: "cereal", key: "cereal" },
        { translate: "sandwiches", key: "sandwiches" },
        { translate: "appetizer", key: "appetizer" },
        { translate: "soup", key: "soup" },
        { translate: "rice", key: "rice" },
        { translate: "salad", key: "salad" },
        { translate: "pastry", key: "pastry" },
        { translate: "favorite", key: "favorite" },
        { translate: "enough", key: "enough" },
        { translate: "satisfied", key: "satisfied" },
    ],
    "fruits": [
        { translate: "cherry", key: "cherry" },
        { translate: "figs", key: "figs" },
        { translate: "grapefruit", key: "grapefruit" },
        { translate: "peaches", key: "peaches" },
        { translate: "pears", key: "pears" },
        { translate: "bananas", key: "bananas" },
        { translate: "guava", key: "guava" },
        { translate: "coconut", key: "coconut" },
        { translate: "plum", key: "plum" },
        { translate: "apricot", key: "apricot" },
        { translate: "mango", key: "mango" },
        { translate: "grapes", key: "grapes" },
        { translate: "orange", key: "orange" },
        { translate: "lemon", key: "lemon" },
        { translate: "lime", key: "lime" },
        { translate: "tangerine", key: "tangerine" },
        { translate: "blueberry", key: "blueberry" },
        { translate: "raspberry", key: "raspberry" },
        { translate: "strawberry", key: "strawberry" },
        { translate: "cantaloupe", key: "cantaloupe" },
        { translate: "pineapple", key: "pineapple" },
        { translate: "watermelon", key: "watermelon" },
    ],
    "vegetables": [
        { translate: "artichoke", key: "artichoke" },
        { translate: "asparagus", key: "asparagus" },
        { translate: "broccoli", key: "broccoli" },
        { translate: "cabbage", key: "cabbage" },
        { translate: "carrots", key: "carrots" },
        { translate: "celery", key: "celery" },
        { translate: "corn", key: "corn" },
        { translate: "cucumber", key: "cucumber" },
        { translate: "lettuce", key: "lettuce" },
        { translate: "mushroom", key: "mushroom" },
        { translate: "pepper", key: "pepper" },
        { translate: "pickle", key: "pickle" },
        { translate: "pumpkin", key: "pumpkin" },
        { translate: "radish", key: "radish" },
        { translate: "spinach", key: "spinach" },
        { translate: "squash", key: "squash" },
        { translate: "sweet potato", key: "sweet potato" },
        { translate: "tomato", key: "tomato" },
        { translate: "peas", key: "peas" },
        { translate: "beans", key: "beans" },
        { translate: "Brussels sprouts", key: "brussels sprouts" },
        { translate: "cauliflower", key: "cauliflower" },
        { translate: "onions", key: "onions" },
        { translate: "potato", key: "potato" },
    ],
    "meats": [
        { translate: "fish", key: "fish" },
        { translate: "poultry", key: "poultry" },
        { translate: "chicken", key: "chicken" },
        { translate: "turkey", key: "turkey" },
        { translate: "red meat", key: "red meat" },
        { translate: "beef", key: "beef" },
        { translate: "lamb", key: "lamb" },
        { translate: "mutton", key: "mutton" },
        { translate: "kebabs", key: "kebabs" },
        { translate: "barbecue", key: "barbecue" },
        { translate: "sausage", key: "sausage" },
        { translate: "pork", key: "pork" },
        { translate: "biltong", key: "biltong" },
    ],
    "beverages": [
        { translate: "water", key: "water" },
        { translate: "milk", key: "milk" },
        { translate: "fruit juices", key: "fruit juices" },
        { translate: "orange juice", key: "orange juice" },
        { translate: "tangerine juice", key: "tangerine juice" },
        { translate: "grapefruit juice", key: "grapefruit juice" },
        { translate: "apple juice", key: "apple juice" },
        { translate: "guava juice", key: "guava juice" },
        { translate: "passion fruit juice", key: "passion fruit juice" },
        { translate: "coffee", key: "coffee" },
        { translate: "tea", key: "tea" },
        { translate: "hot chocolate", key: "hot chocolate" },
        { translate: "pop", key: "pop" },
        { translate: "alcohol", key: "alcohol" },
        { translate: "wine", key: "wine" },
        { translate: "beer", key: "beer" },
    ],
    "dessert": [
        { translate: "cream", key: "cream" },
        { translate: "ice cream", key: "ice cream" },
        { translate: "custard", key: "custard" },
        { translate: "fruit salad", key: "fruit salad" },
        { translate: "rice pudding", key: "rice pudding" },
        { translate: "cake", key: "cake" },
        { translate: "pie", key: "pie" },
        { translate: "apple pie", key: "apple pie" },
        { translate: "chocolate", key: "chocolate" },
    ],
    "seasonings and condiments": [
        { translate: "spices", key: "spices" },
        { translate: "salt", key: "salt" },
        { translate: "pepper", key: "pepper" },
        { translate: "curry", key: "curry" },
        { translate: "coriander", key: "coriander" },
        { translate: "cumin seeds", key: "cumin seeds" },
        { translate: "basil", key: "basil" },
        { translate: "ginger", key: "ginger" },
        { translate: "cinnamon", key: "cinnamon" },
        { translate: "garlic", key: "garlic" },
        { translate: "turmeric", key: "turmeric" },
        { translate: "dill", key: "dill" },
        { translate: "mustard", key: "mustard" },
        { translate: "mint", key: "mint" },
        { translate: "nutmeg", key: "nutmeg" },
        { translate: "vinegar", key: "vinegar" },
        { translate: "oregano", key: "oregano" },
        { translate: "ketchup", key: "ketchup" },
        { translate: "mayonnaise", key: "mayonnaise" },
        { translate: "cayenne pepper", key: "cayenne pepper" },
        { translate: "olive oil", key: "olive oil" },
        { translate: "soy sauce", key: "soy sauce" },
    ],
    "grains nuts and seeds": [
        { translate: "almonds", key: "almonds" },
        { translate: "cashews", key: "cashews" },
        { translate: "flax seeds", key: "flax seeds" },
        { translate: "olive oil", key: "olive oil" },
        { translate: "peanuts", key: "peanuts" },
        { translate: "pumpkin seeds", key: "pumpkin seeds" },
        { translate: "sunflower seeds", key: "sunflower seeds" },
        { translate: "sesame seeds", key: "sesame seeds" },
        { translate: "walnuts", key: "walnuts" },
        { translate: "barley", key: "barley" },
        { translate: "brown rice", key: "brown rice" },
        { translate: "buckwheat", key: "buckwheat" },
        { translate: "corn", key: "corn" },
        { translate: "oats", key: "oats" },
        { translate: "rye", key: "rye" },
        { translate: "wheat", key: "wheat" },
    ],
    "food related actions": [
        { translate: "to peel", key: "to peel" },
        { translate: "to wash", key: "to wash" },
        { translate: "to chop", key: "to chop" },
        { translate: "to dice", key: "to dice" },
        { translate: "to cut", key: "to cut" },
        { translate: "to simmer", key: "to simmer" },
        { translate: "to boil", key: "to boil" },
        { translate: "to cook", key: "to cook" },
        { translate: "to steam", key: "to steam" },
        { translate: "to broil", key: "to broil" },
        { translate: "to taste", key: "to taste" },
        { translate: "to sip", key: "to sip" },
        { translate: "to drink", key: "to drink" },
        { translate: "to smell", key: "to smell" },
        { translate: "to watch", key: "to watch" },
        { translate: "to stir", key: "to stir" },
        { translate: "to whip", key: "to whip" },
        { translate: "to spill", key: "to spill" },
    ],
    "eating out": [
        { translate: "to make a reservation", key: "to make a reservation" },
        { translate: "to cancel a reservation", key: "to cancel a reservation" },
        { translate: "waiter", key: "waiter" },
        { translate: "menu", key: "menu" },
        { translate: "to order", key: "to order" },
        { translate: "appetizer", key: "appetizer" },
        { translate: "entree", key: "entree" },
        { translate: "rare", key: "rare" },
        { translate: "well done", key: "well done" },
        { translate: "dessert", key: "dessert" },
        { translate: "to tip", key: "to tip" },
    ],
    "jewelry": [
        { translate: "ring", key: "ring" },
        { translate: "precious stones", key: "precious stones" },
        { translate: "pearls", key: "pearls" },
        { translate: "diamonds", key: "diamonds" },
        { translate: "emeralds", key: "emeralds" },
        { translate: "rubies", key: "rubies" },
        { translate: "gold", key: "gold" },
        { translate: "silver", key: "silver" },
        { translate: "platinum", key: "platinum" },
        { translate: "engagement ring", key: "engagement ring" },
        { translate: "wedding ring", key: "wedding ring" },
        { translate: "necklace", key: "necklace" },
        { translate: "choker", key: "choker" },
        { translate: "pendant", key: "pendant" },
        { translate: "watch", key: "watch" },
        { translate: "earrings", key: "earrings" },
        { translate: "bracelet or bangle", key: "bracelet or bangle" },
        { translate: "ankle bracelet", key: "ankle bracelet" },
        { translate: "charm", key: "charm" },
        { translate: "cuff links", key: "cuff links" },
        { translate: "chain", key: "chain" },
        { translate: "pin or brooch", key: "pin or brooch" },
        { translate: "beads", key: "beads" },
    ],
    "toiletries and cosmetics": [
        { translate: "comb", key: "comb" },
        { translate: "brush", key: "brush" },
        { translate: "toothbrush", key: "toothbrush" },
        { translate: "toothpaste", key: "toothpaste" },
        { translate: "shaving cream", key: "shaving cream" },
        { translate: "razor", key: "razor" },
        { translate: "deodorant", key: "deodorant" },
        { translate: "aftershave", key: "aftershave" },
        { translate: "soap", key: "soap" },
        { translate: "wash cloth", key: "wash cloth" },
        { translate: "shampoo", key: "shampoo" },
        { translate: "towel", key: "towel" },
        { translate: "clean", key: "clean" },
        { translate: "dirty", key: "dirty" },
        { translate: "nail file", key: "nail file" },
        { translate: "pair of scissors", key: "pair of scissors" },
        { translate: "ointment", key: "ointment" },
        { translate: "makeup", key: "makeup" },
        { translate: "perfume", key: "perfume" },
        { translate: "lipstick", key: "lipstick" },
        { translate: "nail polish", key: "nail polish" },
        { translate: "blush", key: "blush" },
        { translate: "eye shadow", key: "eye shadow" },
        { translate: "mascara", key: "mascara" },
    ],
    "general health": [
        { translate: "healthy or health", key: "healthy or health" },
        { translate: "health", key: "health" },
        { translate: "fiber", key: "fiber" },
        { translate: "organic", key: "organic" },
        { translate: "exercise", key: "exercise" },
        { translate: "fit", key: "fit" },
        { translate: "hygiene, hygienic", key: "hygiene, hygienic" },
        { translate: "to be ill", key: "to be ill" },
        { translate: "virus", key: "virus" },
        { translate: "bacteria", key: "bacteria" },
        { translate: "disinfect infect", key: "disinfect infect" },
        { translate: "blood", key: "blood" },
        { translate: "infectious disease", key: "infectious disease" },
        { translate: "allergic", key: "allergic" },
        { translate: "relief", key: "relief" },
        { translate: "ice", key: "ice" },
        { translate: "recover", key: "recover" },
        { translate: "healing", key: "healing" },
        { translate: "mental health", key: "mental health" },
        { translate: "stressed", key: "stressed" },
    ],
    "ailments and symptoms": [
        { translate: "to sweat", key: "to sweat" },
        { translate: "to vomit", key: "to vomit" },
        { translate: "to cough", key: "to cough" },
        { translate: "to groan", key: "to groan" },
        { translate: "to itch", key: "to itch" },
        { translate: "to have a headache", key: "to have a headache" },
        { translate: "to have a fever", key: "to have a fever" },
        { translate: "to have chills", key: "to have chills" },
        { translate: "to have a stomach ache", key: "to have a stomach ache" },
        { translate: "to have a back ache", key: "to have a back ache" },
        { translate: "to have a weak appetite", key: "to have a weak appetite" },
        { translate: "to have a blocked nose", key: "to have a blocked nose" },
        { translate: "to have diarrhea", key: "to have diarrhea" },
        { translate: "to have an infection", key: "to have an infection" },
        { translate: "to have a concussion", key: "to have a concussion" },
        { translate: "to have a rash", key: "to have a rash" },
        { translate: "to have a cut", key: "to have a cut" },
        { translate: "to have an injury", key: "to have an injury" },
        { translate: "to have a fracture", key: "to have a fracture" },
        { translate: "to have a bruise", key: "to have a bruise" },
        { translate: "to have a wound", key: "to have a wound" },
        { translate: "to be sleepy", key: "to be sleepy" },
        { translate: "to be swollen", key: "to be swollen" },
        { translate: "to feel dizzy", key: "to feel dizzy" },
        { translate: "to feel bad", key: "to feel bad" },
        { translate: "to feel nauseous", key: "to feel nauseous" },
        { translate: "to be sore", key: "to be sore" },
        { translate: "poison", key: "poison" },
        { translate: "swelling", key: "swelling" },
        { translate: "illness", key: "illness" },
    ],
    "illnesses": [
        { translate: "a cold", key: "a cold" },
        { translate: "flu", key: "flu" },
        { translate: "measles", key: "measles" },
        { translate: "mumps", key: "mumps" },
        { translate: "chicken pox", key: "chicken pox" },
        { translate: "malaria", key: "malaria" },
        { translate: "cancer", key: "cancer" },
        { translate: "inflammation", key: "inflammation" },
        { translate: "pneumonia", key: "pneumonia" },
        { translate: "sinusitis", key: "sinusitis" },
        { translate: "jaundice", key: "jaundice" },
        { translate: "meningitis", key: "meningitis" },
        { translate: "rheumatism", key: "rheumatism" },
        { translate: "HIV", key: "hiv" },
        { translate: "AIDS", key: "aids" },
        { translate: "diabetes", key: "diabetes" },
        { translate: "asthma", key: "asthma" },
        { translate: "tuberculosis", key: "tuberculosis" },
    ],
    "doctor": [
        { translate: "to visit", key: "to visit" },
        { translate: "hospital", key: "hospital" },
        { translate: "waiting room", key: "waiting room" },
        { translate: "clinic", key: "clinic" },
        { translate: "make an appointment", key: "make an appointment" },
        { translate: "to confirm", key: "to confirm" },
        { translate: "nurse", key: "nurse" },
        { translate: "receptionist", key: "receptionist" },
        { translate: "examination", key: "examination" },
        { translate: "thermometer", key: "thermometer" },
        { translate: "prescription", key: "prescription" },
        { translate: "medicine", key: "medicine" },
        { translate: "to expect", key: "to expect" },
        { translate: "surgeon", key: "surgeon" },
        { translate: "stethoscope", key: "stethoscope" },
        { translate: "x rays", key: "x rays" },
        { translate: "pulse", key: "pulse" },
        { translate: "needle", key: "needle" },
        { translate: "injection", key: "injection" },
        { translate: "abortion", key: "abortion" },
        { translate: "mortuary", key: "mortuary" },
        { translate: "optometrist", key: "optometrist" },
        { translate: "ophthalmologist", key: "ophthalmologist" },
    ],
    "hospital": [
        { translate: "pharmacy", key: "pharmacy" },
        { translate: "medicine", key: "medicine" },
        { translate: "medication", key: "medication" },
        { translate: "plaster", key: "plaster" },
        { translate: "bandage", key: "bandage" },
        { translate: "anti malaria tablets", key: "anti malaria tablets" },
        { translate: "antibiotic", key: "antibiotic" },
        { translate: "aspirin", key: "aspirin" },
        { translate: "antihistamine", key: "antihistamine" },
        { translate: "sick bay", key: "sick bay" },
        { translate: "female ward", key: "female ward" },
        { translate: "male ward", key: "male ward" },
        { translate: "ambulance", key: "ambulance" },
        { translate: "emergency room", key: "emergency room" },
        { translate: "blood pressure", key: "blood pressure" },
        { translate: "intensive care", key: "intensive care" },
        { translate: "lab", key: "lab" },
        { translate: "operating room", key: "operating room" },
        { translate: "operation", key: "operation" },
        { translate: "x rays", key: "x rays" },
        { translate: "routine check up", key: "routine check up" },
        { translate: "physical", key: "physical" },
        { translate: "physical therapy", key: "physical therapy" },
        { translate: "visiting hours", key: "visiting hours" },
        { translate: "wheelchair", key: "wheelchair" },
    ],
    "physical examination": [
        { translate: "How are you today", key: "how are you today" },
        { translate: "Can you breathe", key: "can you breathe" },
        { translate: "Can you swallow", key: "can you swallow" },
        { translate: "Where does it hurt", key: "where does it hurt" },
        { translate: "Does it hurt when I touch here", key: "does it hurt when i touch here" },
        { translate: "Do you have a fever", key: "do you have a fever" },
        { translate: "Have you vomited", key: "have you vomited" },
        { translate: "Do you have diarrhea", key: "do you have diarrhea" },
        { translate: "Do you have a headache", key: "do you have a headache" },
        { translate: "Do you have cramps", key: "do you have cramps" },
        { translate: "Do you feel dizzy or weak", key: "do you feel dizzy or weak" },
        { translate: "When did you last eat", key: "when did you last eat" },
        { translate: "Can you keep food down", key: "can you keep food down" },
        { translate: "Can you hear me", key: "can you hear me" },
        { translate: "Lie down on your back please", key: "lie down on your back please" },
        { translate: "Lie down on your stomach please", key: "lie down on your stomach please" },
        { translate: "Are you comfortable", key: "are you comfortable" },
        { translate: "Follow the movement of my finger with your eyes", key: "follow the movement of my finger with your eyes" },
        { translate: "Squeeze my fingers", key: "squeeze my fingers" },
        { translate: "Cough, please", key: "cough please" },
        { translate: "Stand up", key: "stand up" },
        { translate: "Touch your toes", key: "touch your toes" },
    ],
    "dentist": [
        { translate: "tooth", key: "tooth" },
        { translate: "to brush", key: "to brush" },
        { translate: "to floss", key: "to floss" },
        { translate: "braces", key: "braces" },
        { translate: "dentist", key: "dentist" },
        { translate: "dental hygienist", key: "dental hygienist" },
        { translate: "toothache", key: "toothache" },
        { translate: "cavity", key: "cavity" },
        { translate: "drill a hole", key: "drill a hole" },
        { translate: "filling", key: "filling" },
        { translate: "pull a tooth", key: "pull a tooth" },
        { translate: "lose a tooth", key: "lose a tooth" },
        { translate: "crown", key: "crown" },
        { translate: "bridge", key: "bridge" },
        { translate: "dentures", key: "dentures" },
    ],
    "dwelling": [
        { translate: "shack", key: "shack" },
        { translate: "tent", key: "tent" },
        { translate: "hut", key: "hut" },
        { translate: "studio apartment", key: "studio apartment" },
        { translate: "two bedroom apartment", key: "two bedroom apartment" },
        { translate: "apartment", key: "apartment" },
        { translate: "condominium", key: "condominium" },
        { translate: "duplex", key: "duplex" },
        { translate: "townhouse", key: "townhouse" },
        { translate: "house", key: "house" },
        { translate: "castle", key: "castle" },
    ],
    "house outside": [
        { translate: "outside", key: "outside" },
        { translate: "the garden", key: "the garden" },
        { translate: "to mow the lawn", key: "to mow the lawn" },
        { translate: "climb the stairs", key: "climb the stairs" },
        { translate: "to sit on the porch", key: "to sit on the porch" },
        { translate: "to open the gate", key: "to open the gate" },
        { translate: "to sweep the path", key: "to sweep the path" },
        { translate: "to knock on the front door", key: "to knock on the front door" },
        { translate: "build the wall", key: "build the wall" },
        { translate: "the roof leaks", key: "the roof leaks" },
        { translate: "wash the window", key: "wash the window" },
        { translate: "lock the garage", key: "lock the garage" },
    ],
    "house inside": [
        { translate: "the living room", key: "the living room" },
        { translate: "the family room", key: "the family room" },
        { translate: "switch on the light", key: "switch on the light" },
        { translate: "wall to wall carpet", key: "wall to wall carpet" },
        { translate: "paint the painting", key: "paint the painting" },
        { translate: "paint", key: "paint" },
        { translate: "the ceiling", key: "the ceiling" },
        { translate: "dining room", key: "dining room" },
        { translate: "the salt shaker", key: "the salt shaker" },
        { translate: "the pepper shaker", key: "the pepper shaker" },
        { translate: "the napkin", key: "the napkin" },
        { translate: "knife", key: "knife" },
        { translate: "fork", key: "fork" },
        { translate: "spoon", key: "spoon" },
        { translate: "plate", key: "plate" },
        { translate: "bowl", key: "bowl" },
        { translate: "glass", key: "glass" },
        { translate: "the dining room set", key: "the dining room set" },
        { translate: "the table cloth", key: "the table cloth" },
        { translate: "the curtains", key: "the curtains" },
        { translate: "the fruit bowl", key: "the fruit bowl" },
        { translate: "to have a meal", key: "to have a meal" },
        { translate: "the kitchen", key: "the kitchen" },
        { translate: "bread box", key: "bread box" },
        { translate: "the kitchen cupboard", key: "the kitchen cupboard" },
        { translate: "in the drawer", key: "in the drawer" },
        { translate: "the shelf", key: "the shelf" },
        { translate: "the basin", key: "the basin" },
        { translate: "to pass", key: "to pass" },
        { translate: "pot lid", key: "pot lid" },
        { translate: "the frying pan", key: "the frying pan" },
        { translate: "back door", key: "back door" },
        { translate: "switch", key: "switch" },
    ],
    "furniture": [
        { translate: "the couch", key: "the couch" },
        { translate: "the armchair", key: "the armchair" },
        { translate: "the coffee table", key: "the coffee table" },
        { translate: "the book shelf", key: "the book shelf" },
        { translate: "the table", key: "the table" },
        { translate: "the buffet", key: "the buffet" },
        { translate: "chairs", key: "chairs" },
        { translate: "the laundry room", key: "the laundry room" },
        { translate: "the tap, faucet", key: "the tap, faucet" },
        { translate: "cold water", key: "cold water" },
        { translate: "the bedroom", key: "the bedroom" },
        { translate: "the bed", key: "the bed" },
        { translate: "to make the bed", key: "to make the bed" },
        { translate: "the mattress", key: "the mattress" },
        { translate: "the sheet", key: "the sheet" },
        { translate: "blanket", key: "blanket" },
        { translate: "to tuck in the blanket", key: "to tuck in the blanket" },
        { translate: "pillow", key: "pillow" },
        { translate: "the cover", key: "the cover" },
        { translate: "chest of drawers", key: "chest of drawers" },
        { translate: "the closet", key: "the closet" },
        { translate: "study desk", key: "study desk" },
        { translate: "study", key: "study" },
        { translate: "the bathroom", key: "the bathroom" },
        { translate: "the bath", key: "the bath" },
        { translate: "to run the water", key: "to run the water" },
        { translate: "the shower", key: "the shower" },
        { translate: "to look in the mirror", key: "to look in the mirror" },
        { translate: "the toilet", key: "the toilet" },
        { translate: "to flush the toilet", key: "to flush the toilet" },
    ],
    "appliances": [
        { translate: "washing machine", key: "washing machine" },
        { translate: "washing powder", key: "washing powder" },
        { translate: "dryer", key: "dryer" },
        { translate: "microwave", key: "microwave" },
        { translate: "the fridge", key: "the fridge" },
        { translate: "to cool", key: "to cool" },
        { translate: "the freezer", key: "the freezer" },
        { translate: "to freeze", key: "to freeze" },
        { translate: "stove", key: "stove" },
        { translate: "electric", key: "electric" },
        { translate: "gas", key: "gas" },
        { translate: "to bake", key: "to bake" },
        { translate: "to fry", key: "to fry" },
        { translate: "to cook", key: "to cook" },
        { translate: "burner", key: "burner" },
        { translate: "dishwasher", key: "dishwasher" },
        { translate: "the radio", key: "the radio" },
        { translate: "television", key: "television" },
        { translate: "computer", key: "computer" },
        { translate: "kettle", key: "kettle" },
        { translate: "coffee maker", key: "coffee maker" },
        { translate: "the iron", key: "the iron" },
        { translate: "air conditioner", key: "air conditioner" },
        { translate: "vacuum cleaner", key: "vacuum cleaner" },
    ],
    "cooking ware": [
        { translate: "cutting board", key: "cutting board" },
        { translate: "bowl", key: "bowl" },
        { translate: "colander", key: "colander" },
        { translate: "egg beater", key: "egg beater" },
        { translate: "fork", key: "fork" },
        { translate: "frying pan", key: "frying pan" },
        { translate: "grater", key: "grater" },
        { translate: "jug", key: "jug" },
        { translate: "knife", key: "knife" },
        { translate: "ladle", key: "ladle" },
        { translate: "peeler", key: "peeler" },
        { translate: "plate", key: "plate" },
        { translate: "sauce pan", key: "sauce pan" },
        { translate: "sieve", key: "sieve" },
        { translate: "spatula", key: "spatula" },
        { translate: "spoon", key: "spoon" },
        { translate: "tablespoon", key: "tablespoon" },
        { translate: "teaspoon", key: "teaspoon" },
        { translate: "tongs", key: "tongs" },
        { translate: "whisk", key: "whisk" },
    ],
    "house cleaning": [
        { translate: "deodorizer", key: "deodorizer" },
        { translate: "dust pan", key: "dust pan" },
        { translate: "a mop", key: "a mop" },
        { translate: "duster", key: "duster" },
        { translate: "to dust", key: "to dust" },
        { translate: "dusting rag", key: "dusting rag" },
        { translate: "broom", key: "broom" },
        { translate: "to sweep out", key: "to sweep out" },
        { translate: "rag", key: "rag" },
        { translate: "to wipe off", key: "to wipe off" },
        { translate: "scrubbing brush", key: "scrubbing brush" },
        { translate: "sponge", key: "sponge" },
        { translate: "vacuum cleaner", key: "vacuum cleaner" },
        { translate: "to vacuum", key: "to vacuum" },
        { translate: "garbage", key: "garbage" },
        { translate: "trash can", key: "trash can" },
        { translate: "laundry detergent", key: "laundry detergent" },
        { translate: "bleach", key: "bleach" },
        { translate: "to do laundry", key: "to do laundry" },
        { translate: "recycling bin", key: "recycling bin" },
    ],
    "parental phrases": [
        { translate: "Are you hungry", key: "Are you hungry" },
        { translate: "Are you thirsty", key: "Are you thirsty" },
        { translate: "What do you want to eat", key: "What do you want to eat" },
        { translate: "Would you like a glass of water", key: "Would you like a glass of water" },
        { translate: "Do you want to play", key: "Do you want to play" },
        { translate: "Are you tired", key: "Are you tired" },
        { translate: "Put away your toys, please", key: "Put away your toys, please" },
        { translate: "it is time to go to sleep", key: "it is time to go to sleep" },
        { translate: "Would you like me to read you a story", key: "Would you like me to read you a story" },
        { translate: "Are you scared of the dark", key: "Are you scared of the dark" },
        { translate: "do not be scared", key: "do not be scared" },
        { translate: "You are safe here", key: "You are safe here" },
        { translate: "do not hit your sister", key: "do not hit your sister" },
        { translate: "Wait please", key: "Wait please" },
        { translate: "Just one cookie", key: "Just one cookie" },
        { translate: "Behave, please", key: "Behave, please" },
        { translate: "Watch out", key: "Watch out" },
        { translate: "Stop that, please", key: "Stop that, please" },
        { translate: "Sit down, please", key: "Sit down, please" },
        { translate: "do not yell at me", key: "do not yell at me" },
        { translate: "I am not finished talking to you", key: "I am not finished talking to you" },
        { translate: "Say sorry to your brother", key: "Say sorry to your brother" },
        { translate: "Come here please", key: "Come here please" },
        { translate: "Stay here please", key: "Stay here please" },
        { translate: "do not jump on the bed, please", key: "do not jump on the bed, please" },
        { translate: "Are you hurt", key: "Are you hurt" },
        { translate: "Where does it hurt", key: "Where does it hurt" },
        { translate: "do not cry", key: "do not cry" },
        { translate: "Be good", key: "Be good" },
        { translate: "Do you want to go potty", key: "Do you want to go potty" },
        { translate: "Do you want to go pee", key: "Do you want to go pee" },
        { translate: "Do you want to play a game", key: "Do you want to play a game" },
        { translate: "Do you want to watch TV", key: "Do you want to watch TV" },
        { translate: "Let us go to the park", key: "Let us go to the park" },
        { translate: "Let us go play on the swings", key: "Let us go play on the swings" },
    ],
    "child care": [
        { translate: "to change a diaper", key: "to change a diaper" },
        { translate: "babysitter", key: "babysitter" },
        { translate: "nursery", key: "nursery" },
        { translate: "bottle", key: "bottle" },
        { translate: "formula", key: "formula" },
        { translate: "to breast feed", key: "to breast feed" },
        { translate: "to read a story", key: "to read a story" },
        { translate: "to play a game", key: "to play a game" },
        { translate: "to put to bed", key: "to put to bed" },
    ],
    "city": [
        { translate: "building", key: "building" },
        { translate: "security guard", key: "security guard" },
        { translate: "elevator", key: "elevator" },
        { translate: "escalator", key: "escalator" },
        { translate: "office", key: "office" },
        { translate: "post office", key: "post office" },
        { translate: "postbox", key: "postbox" },
        { translate: "airmail", key: "airmail" },
        { translate: "package", key: "package" },
        { translate: "newspaper", key: "newspaper" },
        { translate: "gas station", key: "gas station" },
        { translate: "square", key: "square" },
        { translate: "tower", key: "tower" },
        { translate: "skyscraper", key: "skyscraper" },
        { translate: "business center", key: "business center" },
        { translate: "shopping center", key: "shopping center" },
        { translate: "hair dresser", key: "hair dresser" },
        { translate: "sale, sell", key: "sale, sell" },
        { translate: "park", key: "park" },
        { translate: "museum", key: "museum" },
        { translate: "movies", key: "movies" },
        { translate: "department store", key: "department store" },
        { translate: "market", key: "market" },
        { translate: "convenience store", key: "convenience store" },
        { translate: "restaurant", key: "restaurant" },
        { translate: "theater", key: "theater" },
        { translate: "performance", key: "performance" },
    ],
    "traffic": [
        { translate: "highway", key: "highway" },
        { translate: "lanes", key: "lanes" },
        { translate: "bridge", key: "bridge" },
        { translate: "circle", key: "circle" },
        { translate: "corner", key: "corner" },
        { translate: "sidewalk", key: "sidewalk" },
        { translate: "road", key: "road" },
        { translate: "lane", key: "lane" },
        { translate: "road signs", key: "road signs" },
        { translate: "exit", key: "exit" },
        { translate: "only", key: "only" },
        { translate: "pedestrian crossing", key: "pedestrian crossing" },
        { translate: "traffic light", key: "traffic light" },
        { translate: "stop", key: "stop" },
        { translate: "dead end", key: "dead end" },
        { translate: "one way", key: "one way" },
        { translate: "keep left", key: "keep left" },
        { translate: "slow down", key: "slow down" },
        { translate: "speed limit", key: "speed limit" },
    ],
    "banking": [
        { translate: "plus", key: "plus" },
        { translate: "minus", key: "minus" },
        { translate: "multiplied by", key: "multiplied by" },
        { translate: "divided by", key: "divided by" },
        { translate: "is equal to", key: "is equal to" },
        { translate: "savings and loan", key: "savings and loan" },
        { translate: "enquiries", key: "enquiries" },
        { translate: "cashier", key: "cashier" },
        { translate: "to get in line", key: "to get in line" },
        { translate: "to fill out a slip", key: "to fill out a slip" },
        { translate: "to open an account", key: "to open an account" },
        { translate: "savings account", key: "savings account" },
        { translate: "checking account", key: "checking account" },
        { translate: "withdraw", key: "withdraw" },
        { translate: "cash a check", key: "cash a check" },
        { translate: "deposit money", key: "deposit money" },
        { translate: "cash", key: "cash" },
        { translate: "change", key: "change" },
        { translate: "interest rate", key: "interest rate" },
        { translate: "transfer", key: "transfer" },
        { translate: "exchange rate", key: "exchange rate" },
        { translate: "computer", key: "computer" },
        { translate: "make a payment", key: "make a payment" },
        { translate: "online services", key: "online services" },
    ],
    "office supplies": [
        { translate: "calculator", key: "calculator" },
        { translate: "eraser", key: "eraser" },
        { translate: "clipboard", key: "clipboard" },
        { translate: "fileorfolder", key: "fileorfolder" },
        { translate: "hole punch", key: "hole punch" },
        { translate: "notebook", key: "notebook" },
        { translate: "tape", key: "tape" },
        { translate: "tape dispenser", key: "tape dispenser" },
        { translate: "paper clip", key: "paper clip" },
        { translate: "pen", key: "pen" },
        { translate: "pencil", key: "pencil" },
        { translate: "rubber band", key: "rubber band" },
        { translate: "stapler", key: "stapler" },
        { translate: "staple remover", key: "staple remover" },
        { translate: "staples", key: "staples" },
        { translate: "thumbtack", key: "thumbtack" },
        { translate: "a pair of scissors", key: "a pair of scissors" },
    ],
    "travel": [
        { translate: "sightseeing", key: "sightseeing" },
        { translate: "tour guide", key: "tour guide" },
        { translate: "guide book", key: "guide book" },
        { translate: "day trip", key: "day trip" },
        { translate: "souvenirs", key: "souvenirs" },
        { translate: "flight", key: "flight" },
        { translate: "airport", key: "airport" },
        { translate: "airplane", key: "airplane" },
        { translate: "international travel", key: "international travel" },
        { translate: "flight attendant", key: "flight attendant" },
        { translate: "luggage", key: "luggage" },
        { translate: "suitcase", key: "suitcase" },
        { translate: "baggage claim", key: "baggage claim" },
        { translate: "check in counter", key: "check in counter" },
        { translate: "overhead bin", key: "overhead bin" },
        { translate: "ticket", key: "ticket" },
        { translate: "reservation", key: "reservation" },
        { translate: "gate", key: "gate" },
        { translate: "boarding pass", key: "boarding pass" },
        { translate: "passport", key: "passport" },
        { translate: "visa", key: "visa" },
        { translate: "customs", key: "customs" },
        { translate: "metal detector", key: "metal detector" },
        { translate: "nothing to declare", key: "nothing to declare" },
        { translate: "train conductor", key: "train conductor" },
        { translate: "compartment", key: "compartment" },
        { translate: "motel", key: "motel" },
        { translate: "Do not disturb", key: "do not disturb" },
        { translate: "travel alarm clock", key: "travel alarm clock" },
        { translate: "hotel", key: "hotel" },
        { translate: "hostel", key: "hostel" },
        { translate: "bed and breakfast", key: "bed and breakfast" },
        { translate: "room service", key: "room service" },
        { translate: "room key", key: "room key" },
        { translate: "single room", key: "single room" },
        { translate: "double room", key: "double room" },
        { translate: "travelers checks", key: "travelers checks" },
        { translate: "map", key: "map" },
        { translate: "taxi", key: "taxi" },
        { translate: "currency exchange", key: "currency exchange" },
        { translate: "phrasebook", key: "phrasebook" },
        { translate: "postcards", key: "postcards" },
        { translate: "the border", key: "the border" },
    ],
    "art": [
        { translate: "to draw", key: "to draw" },
        { translate: "colored pencils", key: "colored pencils" },
        { translate: "ink", key: "ink" },
        { translate: "to design", key: "to design" },
        { translate: "canvas", key: "canvas" },
        { translate: "creative", key: "creative" },
        { translate: "balance", key: "balance" },
        { translate: "paint", key: "paint" },
        { translate: "to paint", key: "to paint" },
        { translate: "brush", key: "brush" },
        { translate: "to sculpt", key: "to sculpt" },
        { translate: "clay", key: "clay" },
    ],
    "music": [
        { translate: "play", key: "play" },
        { translate: "violin", key: "violin" },
        { translate: "flute", key: "flute" },
        { translate: "guitar", key: "guitar" },
        { translate: "drum", key: "drum" },
        { translate: "organ", key: "organ" },
        { translate: "piano", key: "piano" },
        { translate: "sing", key: "sing" },
        { translate: "note", key: "note" },
        { translate: "soprano", key: "soprano" },
        { translate: "bass", key: "bass" },
        { translate: "alto", key: "alto" },
        { translate: "tenor", key: "tenor" },
        { translate: "choir", key: "choir" },
        { translate: "song", key: "song" },
        { translate: "hymn", key: "hymn" },
        { translate: "folk song", key: "folk song" },
    ],
    "religion": [
        { translate: "denomination", key: "denomination" },
        { translate: "god", key: "god" },
        { translate: "goddess", key: "goddess" },
        { translate: "heaven", key: "heaven" },
        { translate: "hell", key: "hell" },
        { translate: "faith", key: "faith" },
        { translate: "sacred", key: "sacred" },
        { translate: "sin", key: "sin" },
        { translate: "to pray", key: "to pray" },
        { translate: "to fast", key: "to fast" },
        { translate: "to worship", key: "to worship" },
        { translate: "to believe in", key: "to believe in" },
        { translate: "church", key: "church" },
        { translate: "temple", key: "temple" },
        { translate: "synagogue", key: "synagogue" },
        { translate: "mosque", key: "mosque" },
        { translate: "missionary", key: "missionary" },
        { translate: "priest", key: "priest" },
        { translate: "pastor", key: "pastor" },
        { translate: "rabbi", key: "rabbi" },
        { translate: "monk", key: "monk" },
        { translate: "nun", key: "nun" },
        { translate: "angel", key: "angel" },
        { translate: "soul", key: "soul" },
        { translate: "spirit", key: "spirit" },
        { translate: "atheist", key: "atheist" },
    ],
    "sports": [
        { translate: "On the field", key: "on the field" },
        { translate: "rugby", key: "rugby" },
        { translate: "soccer", key: "soccer" },
        { translate: "cricket", key: "cricket" },
        { translate: "softball", key: "softball" },
        { translate: "On the court", key: "on the court" },
        { translate: "tennis", key: "tennis" },
        { translate: "golf", key: "golf" },
        { translate: "bowls", key: "bowls" },
        { translate: "bowling", key: "bowling" },
        { translate: "basketball", key: "basketball" },
        { translate: "volleyball", key: "volleyball" },
        { translate: "athletics", key: "athletics" },
        { translate: "archery", key: "archery" },
        { translate: "In the ring", key: "in the ring" },
        { translate: "boxing", key: "boxing" },
        { translate: "wrestling", key: "wrestling" },
        { translate: "gymnastics", key: "gymnastics" },
        { translate: "to swim", key: "to swim" },
        { translate: "water polo", key: "water polo" },
        { translate: "Sports Words", key: "sports words" },
        { translate: "to play", key: "to play" },
        { translate: "to sprain", key: "to sprain" },
        { translate: "to break", key: "to break" },
        { translate: "to kick", key: "to kick" },
        { translate: "to hit", key: "to hit" },
        { translate: "to catch", key: "to catch" },
        { translate: "to throw", key: "to throw" },
        { translate: "ready", key: "ready" },
        { translate: "to practice or exercise", key: "to practice or exercise" },
        { translate: "to participate", key: "to participate" },
        { translate: "score", key: "score" },
        { translate: "game or match", key: "game or match" },
        { translate: "fit", key: "fit" },
        { translate: "referee", key: "referee" },
    ],
    "games": [
        { translate: "chess", key: "chess" },
        { translate: "checkers", key: "checkers" },
        { translate: "pool, billiards", key: "pool, billiards" },
        { translate: "darts", key: "darts" },
        { translate: "dominoes", key: "dominoes" },
        { translate: "tag or touch", key: "tag or touch" },
        { translate: "hide and seek", key: "hide and seek" },
        { translate: "jump rope", key: "jump rope" },
        { translate: "similar to duck duck goose", key: "similar to duck duck goose" },
        { translate: "table tennis or ping pong", key: "table tennis or ping pong" },
    ],
    "hobbies and leisure": [
        { translate: "fishing", key: "fishing" },
        { translate: "sailing", key: "sailing" },
        { translate: "kite flying", key: "kite flying" },
        { translate: "water skiing", key: "water skiing" },
        { translate: "snow skiing", key: "snow skiing" },
        { translate: "cycling", key: "cycling" },
        { translate: "dancing", key: "dancing" },
        { translate: "making music", key: "making music" },
        { translate: "play computer games", key: "play computer games" },
        { translate: "to read", key: "to read" },
        { translate: "to cook", key: "to cook" },
        { translate: "to watch TV", key: "to watch tv" },
        { translate: "to listen to music", key: "to listen to music" },
        { translate: "to go out with friends", key: "to go out with friends" },
        { translate: "to play football, rugby, tennis, golf, volleyball", key: "to play football rugby tennis golf volleyball" },
        { translate: "collection", key: "collection" },
        { translate: "stamps", key: "stamps" },
        { translate: "coins", key: "coins" },
        { translate: "postcards", key: "postcards" },
        { translate: "jogging", key: "jogging" },
        { translate: "chess", key: "chess" },
        { translate: "darts", key: "darts" },
        { translate: "ping pong", key: "ping pong" },
        { translate: "surfing the Web", key: "surfing the web" },
        { translate: "rollerskating, rollerblading", key: "rollerskating rollerblading" },
    ],
    "life events": [
        { translate: "to date", key: "to date" },
        { translate: "engagement", key: "engagement" },
        { translate: "marriage", key: "marriage" },
        { translate: "to be pregnant or expecting", key: "to be pregnant or expecting" },
        { translate: "to have a baby", key: "to have a baby" },
        { translate: "graduation", key: "graduation" },
        { translate: "promotion", key: "promotion" },
        { translate: "to move", key: "to move" },
        { translate: "immigration", key: "immigration" },
        { translate: "emigration", key: "emigration" },
        { translate: "retire", key: "retire" },
        { translate: "to be fired, laid off", key: "to be fired laid off" },
        { translate: "to break up", key: "to break up" },
        { translate: "divorce", key: "divorce" },
    ],
    "school": [
        { translate: "kindergarten", key: "kindergarten" },
        { translate: "primary school", key: "primary school" },
        { translate: "high school", key: "high school" },
        { translate: "university", key: "university" },
        { translate: "trade", key: "trade" },
        { translate: "principal", key: "principal" },
        { translate: "teacher", key: "teacher" },
        { translate: "self discipline", key: "self discipline" },
        { translate: "periods", key: "periods" },
        { translate: "the bell", key: "the bell" },
        { translate: "recess", key: "recess" },
        { translate: "break", key: "break" },
        { translate: "study", key: "study" },
        { translate: "counselor", key: "counselor" },
        { translate: "coach", key: "coach" },
        { translate: "classroom", key: "classroom" },
        { translate: "gymnasium", key: "gymnasium" },
        { translate: "blackboard or white board", key: "blackboard or white board" },
        { translate: "laboratory", key: "laboratory" },
        { translate: "library", key: "library" },
    ],
    "courses": [
        { translate: "Subjects and courses", key: "subjects and courses" },
        { translate: "Mathematics", key: "mathematics" },
        { translate: "Science", key: "science" },
        { translate: "Biology", key: "biology" },
        { translate: "Geography", key: "geography" },
        { translate: "History", key: "history" },
        { translate: "Accounting", key: "accounting" },
        { translate: "Computer Science", key: "computer science" },
        { translate: "Economics", key: "economics" },
        { translate: "Music", key: "music" },
        { translate: "Art", key: "art" },
        { translate: "Wood shop", key: "wood shop" },
        { translate: "Metal shop", key: "metal shop" },
        { translate: "Afrikaans", key: "afrikaans" },
        { translate: "English", key: "english" },
        { translate: "Sotho", key: "sotho" },
        { translate: "Xhosa", key: "xhosa" },
        { translate: "Zulu", key: "zulu" },
        { translate: "Tswana", key: "tswana" },
    ],
    "vocations": [
        { translate: "architect", key: "architect" },
        { translate: "builder", key: "builder" },
        { translate: "carpenter", key: "carpenter" },
        { translate: "policeman", key: "policeman" },
        { translate: "attorney", key: "attorney" },
        { translate: "judge", key: "judge" },
        { translate: "mayor", key: "mayor" },
        { translate: "school principal", key: "school principal" },
        { translate: "teacher", key: "teacher" },
        { translate: "butcher", key: "butcher" },
        { translate: "baker", key: "baker" },
        { translate: "cook", key: "cook" },
        { translate: "servant", key: "servant" },
        { translate: "waiter", key: "waiter" },
        { translate: "writer or author", key: "writer or author" },
        { translate: "secretary", key: "secretary" },
        { translate: "representative", key: "representative" },
        { translate: "singer", key: "singer" },
        { translate: "mailman", key: "mailman" },
        { translate: "soldier", key: "soldier" },
        { translate: "sailor", key: "sailor" },
        { translate: "barber", key: "barber" },
        { translate: "programmer", key: "programmer" },
        { translate: "webmaster", key: "webmaster" },
    ],
    "intensive form": [
        { translate: "scared stiff", key: "scared stiff" },
        { translate: "dead beat", key: "dead beat" },
        { translate: "quite certain", key: "quite certain" },
        { translate: "sick as a dog", key: "sick as a dog" },
        { translate: "quiet as the grave", key: "quiet as the grave" },
        { translate: "fat as a pig", key: "fat as a pig" },
        { translate: "head over heels", key: "head over heels" },
        { translate: "stone drunk", key: "stone drunk" },
        { translate: "razor sharp", key: "razor sharp" },
        { translate: "brand new", key: "brand new" },
        { translate: "soaking wet", key: "soaking wet" },
        { translate: "ugly as sin", key: "ugly as sin" },
        { translate: "bone dry", key: "bone dry" },
        { translate: "pitch dark", key: "pitch dark" },
        { translate: "snow white", key: "snow white" },
        { translate: "stone dead", key: "stone dead" },
    ],
    "afrikaans intensive forms": [
        { translate: "terrified", key: "terrified" },
        { translate: "weary", key: "weary" },
        { translate: "absolutely sure", key: "absolutely sure" },
        { translate: "fatally ill", key: "fatally ill" },
        { translate: "quietly", key: "quietly" },
        { translate: "lard", key: "lard" },
        { translate: "madly in love", key: "madly in love" },
        { translate: "drunk", key: "drunk" },
        { translate: "sharp", key: "sharp" },
        { translate: "brand new", key: "brand new" },
        { translate: "drenched", key: "drenched" },
        { translate: "ugly", key: "ugly" },
        { translate: "bone dry", key: "bone dry" },
        { translate: "pitch dark", key: "pitch dark" },
        { translate: "white", key: "white" },
        { translate: "stone dead", key: "stone dead" },
    ],
    "think activities": [
        { translate: "to evaluate", key: "to evaluate" },
        { translate: "to identify", key: "to identify" },
        { translate: "calculate", key: "calculate" },
        { translate: "to query", key: "to query" },
        { translate: "to question", key: "to question" },
        { translate: "to compare", key: "to compare" },
        { translate: "to contrast", key: "to contrast" },
        { translate: "to sort", key: "to sort" },
        { translate: "to sequence", key: "to sequence" },
        { translate: "to distinguish", key: "to distinguish" },
        { translate: "to confirm", key: "to confirm" },
        { translate: "to infer", key: "to infer" },
        { translate: "to synthesize", key: "to synthesize" },
        { translate: "to summarize", key: "to summarize" },
    ],
    "mental states": [
        { translate: "alert", key: "alert" },
        { translate: "focused", key: "focused" },
        { translate: "anxious", key: "anxious" },
        { translate: "nervous", key: "nervous" },
        { translate: "attentive", key: "attentive" },
        { translate: "inattentive", key: "inattentive" },
        { translate: "relaxed", key: "relaxed" },
        { translate: "tense", key: "tense" },
        { translate: "confused", key: "confused" },
        { translate: "dazed", key: "dazed" },
    ],
    "emotions": [
        { translate: "joy", key: "joy" },
        { translate: "love", key: "love" },
        { translate: "tense", key: "tense" },
        { translate: "worried", key: "worried" },
        { translate: "ecstatic", key: "ecstatic" },
        { translate: "excited", key: "excited" },
        { translate: "caring", key: "caring" },
        { translate: "a desire", key: "a desire" },
        { translate: "unhappy", key: "unhappy" },
        { translate: "uncertain", key: "uncertain" },
        { translate: "embarrassed", key: "embarrassed" },
        { translate: "surprised", key: "surprised" },
        { translate: "angry", key: "angry" },
        { translate: "hate", key: "hate" },
        { translate: "irritable", key: "irritable" },
        { translate: "contempt", key: "contempt" },
    ],
    "wild animals large": [
        { translate: "elephant", key: "elephant" },
        { translate: "giraffe", key: "giraffe" },
        { translate: "lion", key: "lion" },
        { translate: "tiger", key: "tiger" },
        { translate: "camel", key: "camel" },
        { translate: "rhinoceros", key: "rhinoceros" },
        { translate: "bear", key: "bear" },
        { translate: "panda", key: "panda" },
        { translate: "zebra", key: "zebra" },
        { translate: "buffalo", key: "buffalo" },
        { translate: "gorilla", key: "gorilla" },
        { translate: "leopard", key: "leopard" },
        { translate: "okapi", key: "okapi" },
        { translate: "antelope", key: "antelope" },
        { translate: "hippopotamus", key: "hippopotamus" },
    ],
    "wild animals medium": [
        { translate: "ape", key: "ape" },
        { translate: "baboon", key: "baboon" },
        { translate: "wild boar", key: "wild boar" },
        { translate: "hyena", key: "hyena" },
        { translate: "aardvark", key: "aardvark" },
        { translate: "anteater", key: "anteater" },
        { translate: "wild dog", key: "wild dog" },
        { translate: "jackal", key: "jackal" },
        { translate: "fox", key: "fox" },
    ],
    "wild animals small": [
        { translate: "squirrel", key: "squirrel" },
        { translate: "rabbit", key: "rabbit" },
        { translate: "hare", key: "hare" },
        { translate: "otter", key: "otter" },
        { translate: "hedgehog", key: "hedgehog" },
        { translate: "skunk", key: "skunk" },
        { translate: "raccoon", key: "raccoon" },
        { translate: "rodent", key: "rodent" },
        { translate: "mouse", key: "mouse" },
        { translate: "rat", key: "rat" },
    ],
    "birds": [
        { translate: "dove", key: "dove" },
        { translate: "duck", key: "duck" },
        { translate: "bluebird", key: "bluebird" },
        { translate: "eagle", key: "eagle" },
        { translate: "blue jay", key: "blue jay" },
        { translate: "cardinal", key: "cardinal" },
        { translate: "owl", key: "owl" },
        { translate: "penguin", key: "penguin" },
        { translate: "parrot", key: "parrot" },
        { translate: "hummingbird", key: "hummingbird" },
        { translate: "seagull", key: "seagull" },
        { translate: "toucan", key: "toucan" },
        { translate: "woodpecker", key: "woodpecker" },
        { translate: "ostrich", key: "ostrich" },
        { translate: "robin", key: "robin" },
        { translate: "sparrow", key: "sparrow" },
    ],
    "insects": [
        { translate: "insects", key: "insects" },
        { translate: "ant", key: "ant" },
        { translate: "spiders", key: "spiders" },
        { translate: "cockroach", key: "cockroach" },
        { translate: "butterfly", key: "butterfly" },
        { translate: "caterpillar", key: "caterpillar" },
        { translate: "fly", key: "fly" },
        { translate: "bee", key: "bee" },
        { translate: "wasp", key: "wasp" },
        { translate: "fruit fly", key: "fruit fly" },
        { translate: "moth", key: "moth" },
    ],
    "fish and aquatic life": [
        { translate: "walrus", key: "walrus" },
        { translate: "seal", key: "seal" },
        { translate: "lobster", key: "lobster" },
        { translate: "oyster", key: "oyster" },
        { translate: "shrimp", key: "shrimp" },
        { translate: "crab", key: "crab" },
        { translate: "crayfish", key: "crayfish" },
        { translate: "whale", key: "whale" },
        { translate: "shark", key: "shark" },
        { translate: "dolphin", key: "dolphin" },
        { translate: "tuna", key: "tuna" },
        { translate: "cod", key: "cod" },
        { translate: "herring", key: "herring" },
        { translate: "salmon", key: "salmon" },
        { translate: "sardine", key: "sardine" },
        { translate: "mackerel", key: "mackerel" },
        { translate: "carp", key: "carp" },
        { translate: "trout", key: "trout" },
        { translate: "eel", key: "eel" },
    ],
    "reptiles and amphibians": [
        { translate: "snake", key: "snake" },
        { translate: "alligator", key: "alligator" },
        { translate: "crocodile", key: "crocodile" },
        { translate: "frog", key: "frog" },
        { translate: "toad", key: "toad" },
        { translate: "tadpole", key: "tadpole" },
        { translate: "lizard", key: "lizard" },
        { translate: "iguana", key: "iguana" },
        { translate: "chameleon", key: "chameleon" },
        { translate: "tortoise", key: "tortoise" },
        { translate: "turtle", key: "turtle" },
    ],
    "baby animals": [
        { translate: "calf", key: "calf" },
        { translate: "puppy", key: "puppy" },
        { translate: "kitten", key: "kitten" },
        { translate: "tadpole", key: "tadpole" },
        { translate: "chick", key: "chick" },
        { translate: "cub", key: "cub" },
        { translate: "kid", key: "kid" },
        { translate: "fawn", key: "fawn" },
        { translate: "foal", key: "foal" },
        { translate: "lamb", key: "lamb" },
        { translate: "piglet", key: "piglet" },
        { translate: "bunny", key: "bunny" },
    ],
    "animal sounds": [
        { translate: "sing, chirp", key: "sing chirp" },
        { translate: "meow", key: "meow" },
        { translate: "moo", key: "moo" },
        { translate: "bark, growl", key: "bark growl" },
        { translate: "trumpet", key: "trumpet" },
        { translate: "laugh", key: "laugh" },
        { translate: "bleat", key: "bleat" },
        { translate: "roar", key: "roar" },
        { translate: "hoot", key: "hoot" },
        { translate: "hiss", key: "hiss" },
        { translate: "howl, cry", key: "howl cry" },
        { translate: "buzz", key: "buzz" },
        { translate: "whinny", key: "whinny" },
        { translate: "squeal", key: "squeal" },
        { translate: "buzz", key: "buzz" },
        { translate: "buzz", key: "buzz" },
        { translate: "croak", key: "croak" },
        { translate: "crow", key: "crow" },
        { translate: "cluck", key: "cluck" },
    ],
    "human animal terms": [
        { translate: "human", key: "human" },
        { translate: "animal", key: "animal" },
        { translate: "mouth", key: "mouth" },
        { translate: "beak", key: "beak" },
        { translate: "eat", key: "eat" },
        { translate: "feed", key: "feed" },
        { translate: "die", key: "die" },
        { translate: "die", key: "die" },
        { translate: "stomach", key: "stomach" },
        { translate: "gut", key: "gut" },
        { translate: "feet", key: "feet" },
        { translate: "paws", key: "paws" },
        { translate: "skin", key: "skin" },
        { translate: "hide", key: "hide" },
    ],
    "pets": [
        { translate: "pets", key: "pets" },
        { translate: "cat", key: "cat" },
        { translate: "dog", key: "dog" },
        { translate: "hamster", key: "hamster" },
        { translate: "guinea pig", key: "guinea pig" },
        { translate: "mouse", key: "mouse" },
        { translate: "rat", key: "rat" },
        { translate: "chameleon", key: "chameleon" },
        { translate: "birds", key: "birds" },
        { translate: "goldfish", key: "goldfish" },
    ],
    "farm animals": [
        { translate: "livestock", key: "livestock" },
        { translate: "cattle", key: "cattle" },
        { translate: "goats", key: "goats" },
        { translate: "sheep", key: "sheep" },
        { translate: "pigs", key: "pigs" },
        { translate: "horses", key: "horses" },
        { translate: "veterinarian", key: "veterinarian" },
        { translate: "poultry", key: "poultry" },
        { translate: "chickens", key: "chickens" },
        { translate: "rooster", key: "rooster" },
        { translate: "hen", key: "hen" },
        { translate: "chicks", key: "chicks" },
        { translate: "ducks", key: "ducks" },
        { translate: "turkeys", key: "turkeys" },
    ],
    "zoo animals": [
        { translate: "snake", key: "snake" },
        { translate: "lion", key: "lion" },
        { translate: "ostrich", key: "ostrich" },
        { translate: "hippo", key: "hippo" },
        { translate: "baboon", key: "baboon" },
        { translate: "ape", key: "ape" },
        { translate: "camel", key: "camel" },
        { translate: "giraffe", key: "giraffe" },
        { translate: "crocodile", key: "crocodile" },
        { translate: "dolphin", key: "dolphin" },
        { translate: "eagle", key: "eagle" },
        { translate: "parrot", key: "parrot" },
        { translate: "elephant", key: "elephant" },
    ],
    "universe and planets": [
        { translate: "observatory", key: "observatory" },
        { translate: "galaxy", key: "galaxy" },
        { translate: "universe", key: "universe" },
        { translate: "sun", key: "sun" },
        { translate: "moon", key: "moon" },
        { translate: "stars", key: "stars" },
        { translate: "comet", key: "comet" },
        { translate: "Mercury", key: "mercury" },
        { translate: "Venus", key: "venus" },
        { translate: "Earth, world, globe", key: "earth world globe" },
        { translate: "Mars", key: "mars" },
        { translate: "Jupiter", key: "jupiter" },
        { translate: "Saturn", key: "saturn" },
        { translate: "Uranus", key: "uranus" },
        { translate: "Neptune", key: "neptune" },
    ],
    "landscapes": [
        { translate: "bay", key: "bay" },
        { translate: "beach", key: "beach" },
        { translate: "peninsula", key: "peninsula" },
        { translate: "tide", key: "tide" },
        { translate: "cove", key: "cove" },
        { translate: "lagoon", key: "lagoon" },
        { translate: "delta", key: "delta" },
        { translate: "fjord", key: "fjord" },
        { translate: "gorge or canyon", key: "gorge or canyon" },
        { translate: "cliff", key: "cliff" },
        { translate: "river", key: "river" },
        { translate: "creek", key: "creek" },
        { translate: "waterfall", key: "waterfall" },
        { translate: "stream", key: "stream" },
        { translate: "canal", key: "canal" },
        { translate: "brook", key: "brook" },
        { translate: "reservoir, dam", key: "reservoir, dam" },
        { translate: "moor", key: "moor" },
        { translate: "marsh", key: "marsh" },
        { translate: "water hole", key: "water hole" },
        { translate: "crater", key: "crater" },
        { translate: "desert", key: "desert" },
        { translate: "glacier", key: "glacier" },
        { translate: "glade", key: "glade" },
        { translate: "mountain", key: "mountain" },
        { translate: "prairie", key: "prairie" },
        { translate: "ravine", key: "ravine" },
        { translate: "sand", key: "sand" },
        { translate: "tundra", key: "tundra" },
        { translate: "valley", key: "valley" },
        { translate: "woods or forest", key: "woods or forest" },
        { translate: "hills", key: "hills" },
        { translate: "brush fire", key: "brush fire" },
    ],
    "farm life": [
        { translate: "farmer", key: "farmer" },
        { translate: "tractor", key: "tractor" },
        { translate: "shed", key: "shed" },
        { translate: "corral", key: "corral" },
        { translate: "plowed land", key: "plowed land" },
        { translate: "seed", key: "seed" },
        { translate: "planting", key: "planting" },
        { translate: "harvest", key: "harvest" },
        { translate: "rice", key: "rice" },
        { translate: "corn", key: "corn" },
        { translate: "wheat", key: "wheat" },
        { translate: "vineyard", key: "vineyard" },
        { translate: "fruit", key: "fruit" },
        { translate: "silo", key: "silo" },
        { translate: "straw", key: "straw" },
        { translate: "fertilizer", key: "fertilizer" },
    ],
    "metals and elements": [
        { translate: "earth", key: "earth" },
        { translate: "air", key: "air" },
        { translate: "fire", key: "fire" },
        { translate: "water", key: "water" },
        { translate: "cardboard", key: "cardboard" },
        { translate: "ceramic", key: "ceramic" },
        { translate: "metal", key: "metal" },
        { translate: "paper", key: "paper" },
        { translate: "plastic", key: "plastic" },
        { translate: "wood", key: "wood" },
        { translate: "oxygen", key: "oxygen" },
        { translate: "hydrogen", key: "hydrogen" },
        { translate: "carbon", key: "carbon" },
        { translate: "aluminum", key: "aluminum" },
        { translate: "iron", key: "iron" },
        { translate: "steel", key: "steel" },
        { translate: "copper", key: "copper" },
    ],
    "plants and trees": [
        { translate: "plant", key: "plant" },
        { translate: "flower", key: "flower" },
        { translate: "leaf", key: "leaf" },
        { translate: "petal", key: "petal" },
        { translate: "to grow", key: "to grow" },
        { translate: "root", key: "root" },
        { translate: "seed", key: "seed" },
        { translate: "stem", key: "stem" },
        { translate: "trunk", key: "trunk" },
        { translate: "twig", key: "twig" },
        { translate: "vine", key: "vine" },
        { translate: "bush", key: "bush" },
        { translate: "weeds", key: "weeds" },
        { translate: "shrub", key: "shrub" },
        { translate: "cactus", key: "cactus" },
        { translate: "ivy", key: "ivy" },
        { translate: "tree", key: "tree" },
        { translate: "bark", key: "bark" },
        { translate: "branch, branches", key: "branch, branches" },
        { translate: "oak tree", key: "oak tree" },
        { translate: "pine tree", key: "pine tree" },
        { translate: "palm tree", key: "palm tree" },
        { translate: "willow tree", key: "willow tree" },
        { translate: "jacaranda tree", key: "jacaranda tree" },
    ],
    "weather": [
        { translate: "fog", key: "fog" },
        { translate: "foggy", key: "foggy" },
        { translate: "weather forecast", key: "weather forecast" },
        { translate: "freeze", key: "freeze" },
        { translate: "humid", key: "humid" },
        { translate: "hurricane", key: "hurricane" },
        { translate: "lightning", key: "lightning" },
        { translate: "rain", key: "rain" },
        { translate: "rainy", key: "rainy" },
        { translate: "snow", key: "snow" },
        { translate: "sun", key: "sun" },
        { translate: "sunny", key: "sunny" },
        { translate: "thunderstorm", key: "thunderstorm" },
        { translate: "tornado", key: "tornado" },
        { translate: "warm", key: "warm" },
        { translate: "clear", key: "clear" },
        { translate: "clouds", key: "clouds" },
        { translate: "cloudy", key: "cloudy" },
        { translate: "overcast", key: "overcast" },
        { translate: "cool", key: "cool" },
        { translate: "hot", key: "hot" },
        { translate: "weather", key: "weather" },
        { translate: "wind", key: "wind" },
        { translate: "storm", key: "storm" },
        { translate: "cold front", key: "cold front" },
        { translate: "heat wave", key: "heat wave" },
        { translate: "hail", key: "hail" },
        { translate: "thunder", key: "thunder" },
    ],
    "continents": [
        { translate: "Africa", key: "africa" },
        { translate: "Europe", key: "europe" },
        { translate: "Asia", key: "asia" },
        { translate: "Australia", key: "australia" },
        { translate: "Antarctica", key: "antarctica" },
        { translate: "North America", key: "north america" },
        { translate: "South America", key: "south america" },
    ],
    "regions": [
        { translate: "The Middle East", key: "the middle east" },
        { translate: "The Caribbean Islands", key: "the caribbean islands" },
        { translate: "The Benelux Countries", key: "the benelux countries" },
        { translate: "The Great Lakes", key: "the great lakes" },
        { translate: "Scandinavia", key: "scandinavia" },
        { translate: "Polynesia", key: "polynesia" },
        { translate: "Micronesia", key: "micronesia" },
        { translate: "The Pacific Rim", key: "the pacific rim" },
        { translate: "The Balkans", key: "the balkans" },
        { translate: "Oceania", key: "oceania" },
        { translate: "The Far East", key: "the far east" },
        { translate: "Southeast Asia", key: "southeast asia" },
        { translate: "Southern Africa", key: "southern africa" },
        { translate: "West Africa", key: "west africa" },
        { translate: "Western Europe", key: "western europe" },
        { translate: "Eastern Europe", key: "eastern europe" },
        { translate: "Central America", key: "central america" },
    ],
    "countries and nationalities": [
        { translate: "United States of America", key: "united states of america" },
        { translate: "Americans", key: "americans" },
        { translate: "Canada", key: "canada" },
        { translate: "Canadians", key: "canadians" },
        { translate: "Mexico", key: "mexico" },
        { translate: "Mexicans", key: "mexicans" },
        { translate: "Argentina", key: "argentina" },
        { translate: "Argentinians", key: "argentinians" },
        { translate: "Brazil", key: "brazil" },
        { translate: "Brazilians", key: "brazilians" },
        { translate: "Chile", key: "chile" },
        { translate: "Chileans", key: "chileans" },
        { translate: "Britain, England", key: "britain england" },
        { translate: "British, English", key: "british english" },
        { translate: "France", key: "france" },
        { translate: "French", key: "french" },
        { translate: "Germany", key: "germany" },
        { translate: "Germans", key: "germans" },
        { translate: "Italy", key: "italy" },
        { translate: "Italians", key: "italians" },
        { translate: "Greece", key: "greece" },
        { translate: "Greeks", key: "greeks" },
        { translate: "Spain", key: "spain" },
        { translate: "Spaniards", key: "spaniards" },
        { translate: "Portugal", key: "portugal" },
        { translate: "Portuguese", key: "portuguese" },
        { translate: "The Netherlands, Holland", key: "the netherlands holland" },
        { translate: "Dutch", key: "dutch" },
        { translate: "Austria", key: "austria" },
        { translate: "Austrians", key: "austrians" },
        { translate: "Russia", key: "russia" },
        { translate: "Russians", key: "russians" },
        { translate: "Korea", key: "korea" },
        { translate: "Koreans", key: "koreans" },
        { translate: "India", key: "india" },
        { translate: "Indians", key: "indians" },
        { translate: "China", key: "china" },
        { translate: "Chinese", key: "chinese" },
        { translate: "Japan", key: "japan" },
        { translate: "Japanese", key: "japanese" },
        { translate: "Egypt", key: "egypt" },
        { translate: "Egyptians", key: "egyptians" },
        { translate: "South Africa", key: "south africa" },
        { translate: "South Africans", key: "south africans" },
        { translate: "Kenya", key: "kenya" },
        { translate: "Kenyans", key: "kenyans" },
        { translate: "Nigeria", key: "nigeria" },
        { translate: "Nigerians", key: "nigerians" },
    ],
    "languages": [
        { translate: "Afrikaans", key: "afrikaans" },
        { translate: "Arabic", key: "arabic" },
        { translate: "Armenian", key: "armenian" },
        { translate: "Bengali", key: "bengali" },
        { translate: "Chinese", key: "chinese" },
        { translate: "Dutch", key: "dutch" },
        { translate: "English", key: "english" },
        { translate: "Finnish", key: "finnish" },
        { translate: "French", key: "french" },
        { translate: "German", key: "german" },
        { translate: "Greek", key: "greek" },
        { translate: "Hausa", key: "hausa" },
        { translate: "Hindi", key: "hindi" },
        { translate: "Urdu", key: "urdu" },
        { translate: "Italian", key: "italian" },
        { translate: "Javanese", key: "javanese" },
        { translate: "Korean", key: "korean" },
        { translate: "Mandarin Chinese", key: "mandarin chinese" },
        { translate: "Norwegian", key: "norwegian" },
        { translate: "Persian", key: "persian" },
        { translate: "Polish", key: "polish" },
        { translate: "Portuguese", key: "portuguese" },
        { translate: "Russian", key: "russian" },
        { translate: "Serbian", key: "serbian" },
        { translate: "Sotho", key: "sotho" },
        { translate: "Spanish", key: "spanish" },
        { translate: "Swahili", key: "swahili" },
        { translate: "Swedish", key: "swedish" },
        { translate: "Telugu", key: "telugu" },
        { translate: "Turkish", key: "turkish" },
        { translate: "Wolof", key: "wolof" },
        { translate: "Xhosa", key: "xhosa" },
        { translate: "Yoruba", key: "yoruba" },
        { translate: "Zulu", key: "zulu" },
    ],
    "cities": [
        { translate: "New York", key: "new york" },
        { translate: "London", key: "london" },
        { translate: "Berlin", key: "berlin" },
        { translate: "Paris", key: "paris" },
        { translate: "Moscow", key: "moscow" },
        { translate: "Mumbai", key: "mumbai" },
        { translate: "Lisbon", key: "lisbon" },
        { translate: "Brussels", key: "brussels" },
        { translate: "The Hague", key: "the hague" },
        { translate: "Cologne", key: "cologne" },
        { translate: "Cape Town", key: "cape town" },
    ],
    "texting email": [
        { translate: "to write", key: "to write" },
        { translate: "letter", key: "letter" },
        { translate: "envelope", key: "envelope" },
        { translate: "stamp", key: "stamp" },
        { translate: "telephone", key: "telephone" },
        { translate: "to call", key: "to call" },
        { translate: "to contact", key: "to contact" },
        { translate: "to chat", key: "to chat" },
        { translate: "to email", key: "to email" },
        { translate: "web site", key: "web site" },
        { translate: "to reply", key: "to reply" },
        { translate: "to delete", key: "to delete" },
        { translate: "to forward", key: "to forward" },
    ],
    "television": [
        { translate: "anchorman", key: "anchorman" },
        { translate: "cartoon", key: "cartoon" },
        { translate: "channel", key: "channel" },
        { translate: "commercial", key: "commercial" },
        { translate: "couch potato", key: "couch potato" },
        { translate: "game show", key: "game show" },
        { translate: "guest", key: "guest" },
        { translate: "host", key: "host" },
        { translate: "reality show", key: "reality show" },
        { translate: "remote control", key: "remote control" },
        { translate: "serial", key: "serial" },
        { translate: "sitcom", key: "sitcom" },
        { translate: "soap opera", key: "soap opera" },
        { translate: "talk show", key: "talk show" },
        { translate: "TV News", key: "tv news" },
        { translate: "TV viewers", key: "tv viewers" },
    ],
    "the press": [
        { translate: "morning paper", key: "morning paper" },
        { translate: "afternoon paper", key: "afternoon paper" },
        { translate: "Sunday paper", key: "sunday paper" },
        { translate: "advertisement", key: "advertisement" },
        { translate: "classified section", key: "classified section" },
        { translate: "article", key: "article" },
        { translate: "editorial section", key: "editorial section" },
        { translate: "newsagent", key: "newsagent" },
        { translate: "newspaper", key: "newspaper" },
        { translate: "reviews", key: "reviews" },
        { translate: "section", key: "section" },
        { translate: "to subscribe", key: "to subscribe" },
        { translate: "to unsubscribe", key: "to unsubscribe" },
    ],
    "meetings": [
        { translate: "chairperson", key: "chairperson" },
        { translate: "the meeting is now in session", key: "the meeting is now in session" },
        { translate: "minutes", key: "minutes" },
        { translate: "agenda", key: "agenda" },
        { translate: "vote", key: "vote" },
        { translate: "absent", key: "absent" },
        { translate: "present", key: "present" },
        { translate: "adjourn", key: "adjourn" },
        { translate: "motion", key: "motion" },
        { translate: "accepted", key: "accepted" },
        { translate: "rejected", key: "rejected" },
        { translate: "quorum", key: "quorum" },
    ],
    "communication verbs": [
        { translate: "to welcome", key: "to welcome" },
        { translate: "to invite", key: "to invite" },
        { translate: "to promise", key: "to promise" },
        { translate: "to take an oath", key: "to take an oath" },
        { translate: "to defend", key: "to defend" },
        { translate: "to attack", key: "to attack" },
        { translate: "to explain", key: "to explain" },
        { translate: "to persuade", key: "to persuade" },
        { translate: "to testify", key: "to testify" },
        { translate: "to agree", key: "to agree" },
        { translate: "to give your opinion", key: "to give your opinion" },
        { translate: "to praise", key: "to praise" },
        { translate: "to accept", key: "to accept" },
        { translate: "to reject", key: "to reject" },
        { translate: "to question", key: "to question" },
        { translate: "to argue", key: "to argue" },
        { translate: "to ask", key: "to ask" },
        { translate: "to answer", key: "to answer" },
        { translate: "to interrogate", key: "to interrogate" },
        { translate: "to call", key: "to call" },
        { translate: "to plead", key: "to plead" },
        { translate: "to command", key: "to command" },
        { translate: "to beg", key: "to beg" },
        { translate: "to request", key: "to request" },
        { translate: "to challenge", key: "to challenge" },
        { translate: "to humiliate", key: "to humiliate" },
    ],
    "transport flight": [
        { translate: "ticket", key: "ticket" },
        { translate: "seat", key: "seat" },
        { translate: "luggage", key: "luggage" },
        { translate: "waiting list", key: "waiting list" },
        { translate: "delay", key: "delay" },
        { translate: "departure time", key: "departure time" },
        { translate: "arrival time", key: "arrival time" },
        { translate: "itinerary", key: "itinerary" },
        { translate: "to transfer", key: "to transfer" },
        { translate: "ride", key: "ride" },
        { translate: "to book", key: "to book" },
        { translate: "to cancel", key: "to cancel" },
        { translate: "time zone", key: "time zone" },
        { translate: "I have jetlag", key: "i have jetlag" },
    ],
    "transport vehicles": [
        { translate: "space craft", key: "space craft" },
        { translate: "rocket", key: "rocket" },
        { translate: "helicopter", key: "helicopter" },
        { translate: "airplane", key: "airplane" },
        { translate: "motorboat", key: "motorboat" },
        { translate: "ship", key: "ship" },
        { translate: "hovercraft", key: "hovercraft" },
        { translate: "sail yacht", key: "sail yacht" },
        { translate: "car or automobile", key: "car or automobile" },
        { translate: "motorbike", key: "motorbike" },
        { translate: "bicycle", key: "bicycle" },
        { translate: "truck", key: "truck" },
        { translate: "pickup", key: "pickup" },
        { translate: "bus", key: "bus" },
        { translate: "delivery van", key: "delivery van" },
        { translate: "vehicle", key: "vehicle" },
    ],
    "transport car": [
        { translate: "accelerator", key: "accelerator" },
        { translate: "gas or petrol", key: "gas or petrol" },
        { translate: "battery", key: "battery" },
        { translate: "brake", key: "brake" },
        { translate: "bumper", key: "bumper" },
        { translate: "dashboard", key: "dashboard" },
        { translate: "gear shift", key: "gear shift" },
        { translate: "headlight", key: "headlight" },
        { translate: "horn", key: "horn" },
        { translate: "hubcap", key: "hubcap" },
        { translate: "license plate", key: "license plate" },
        { translate: "rearview mirror", key: "rearview mirror" },
        { translate: "seat", key: "seat" },
        { translate: "speedometer", key: "speedometer" },
        { translate: "taillight", key: "taillight" },
        { translate: "tire", key: "tire" },
        { translate: "trunk", key: "trunk" },
        { translate: "turn signal", key: "turn signal" },
        { translate: "wheel", key: "wheel" },
        { translate: "windshield", key: "windshield" },
        { translate: "windshield wipers", key: "windshield wipers" },
    ],
    "transport bicycle": [
        { translate: "wheel", key: "wheel" },
        { translate: "pedal", key: "pedal" },
        { translate: "spoke", key: "spoke" },
        { translate: "handlebars", key: "handlebars" },
        { translate: "chain", key: "chain" },
        { translate: "tricycle", key: "tricycle" },
        { translate: "helmet", key: "helmet" },
        { translate: "fender", key: "fender" },
        { translate: "lock", key: "lock" },
        { translate: "seat", key: "seat" },
        { translate: "brake", key: "brake" },
        { translate: "pump", key: "pump" },
        { translate: "reflector", key: "reflector" },
        { translate: "tire", key: "tire" },
    ],
    "tools general": [
        { translate: "wrench", key: "wrench" },
        { translate: "handsaw", key: "handsaw" },
        { translate: "caulking gun", key: "caulking gun" },
        { translate: "chainsaw", key: "chainsaw" },
        { translate: "clamp", key: "clamp" },
        { translate: "drill", key: "drill" },
        { translate: "crowbar", key: "crowbar" },
        { translate: "duct tape", key: "duct tape" },
        { translate: "sander", key: "sander" },
        { translate: "flashlight", key: "flashlight" },
        { translate: "hammer", key: "hammer" },
        { translate: "level", key: "level" },
        { translate: "measuring tape", key: "measuring tape" },
        { translate: "nuts", key: "nuts" },
        { translate: "bolts", key: "bolts" },
        { translate: "scraper", key: "scraper" },
        { translate: "plunger", key: "plunger" },
        { translate: "screwdriver", key: "screwdriver" },
        { translate: "staple gun", key: "staple gun" },
        { translate: "vise grip", key: "vise grip" },
    ],
    "tools garden": [
        { translate: "to hoe", key: "to hoe" },
        { translate: "pruning shears", key: "pruning shears" },
        { translate: "edger", key: "edger" },
        { translate: "rake", key: "rake" },
        { translate: "pitchfork", key: "pitchfork" },
        { translate: "shovel", key: "shovel" },
        { translate: "trowel", key: "trowel" },
        { translate: "watering can", key: "watering can" },
        { translate: "hose", key: "hose" },
        { translate: "lawnmower", key: "lawnmower" },
        { translate: "gardening gloves", key: "gardening gloves" },
    ],
    "digital computers": [
        { translate: "desktop computer", key: "desktop computer" },
        { translate: "laptop computer", key: "laptop computer" },
        { translate: "keyboard", key: "keyboard" },
        { translate: "mouse", key: "mouse" },
        { translate: "mouse button", key: "mouse button" },
        { translate: "mousepad", key: "mousepad" },
        { translate: "USB flash drive", key: "usb flash drive" },
        { translate: "desktop", key: "desktop" },
        { translate: "spreadsheet", key: "spreadsheet" },
        { translate: "drop down menu", key: "drop down menu" },
        { translate: "to click", key: "to click" },
        { translate: "database", key: "database" },
        { translate: "to download", key: "to download" },
        { translate: "to drag", key: "to drag" },
        { translate: "to drop", key: "to drop" },
        { translate: "email address", key: "email address" },
        { translate: "FAQ", key: "FAQ" },
        { translate: "file transfer", key: "file transfer" },
        { translate: "folder", key: "folder" },
        { translate: "form", key: "form" },
        { translate: "hard drive", key: "hard drive" },
        { translate: "touchpad", key: "touchpad" },
        { translate: "italics", key: "italics" },
        { translate: "modem", key: "modem" },
    ],
    "digital gadgets": [
        { translate: "mp3 player", key: "mp3 player" },
        { translate: "cell phone", key: "cell phone" },
        { translate: "digital camera", key: "digital camera" },
        { translate: "voice recorder", key: "voice recorder" },
        { translate: "video recorder", key: "video recorder" },
        { translate: "digital watch", key: "digital watch" },
        { translate: "global positioning system", key: "global positioning system" },
        { translate: "netbook", key: "netbook" },
        { translate: "laptop", key: "laptop" },
        { translate: "tablet", key: "tablet" },
    ],
    "digital internet": [
        { translate: "the internet", key: "the internet" },
        { translate: "virtual", key: "virtual" },
        { translate: "discussion group", key: "discussion group" },
        { translate: "to link", key: "to link" },
        { translate: "online", key: "online" },
        { translate: "to friend", key: "to friend" },
        { translate: "bookmark", key: "bookmark" },
        { translate: "browser", key: "browser" },
        { translate: "button", key: "button" },
        { translate: "to click", key: "to click" },
        { translate: "to download", key: "to download" },
        { translate: "to upload", key: "to upload" },
        { translate: "to drag", key: "to drag" },
        { translate: "to drop", key: "to drop" },
        { translate: "email address", key: "email address" },
        { translate: "FAQ", key: "faq" },
        { translate: "file transfer", key: "file transfer" },
        { translate: "folder", key: "folder" },
        { translate: "form", key: "form" },
        { translate: "homepage", key: "homepage" },
        { translate: "hyperlink", key: "hyperlink" },
        { translate: "icon", key: "icon" },
        { translate: "inbox", key: "inbox" },
        { translate: "Internet Service Provider", key: "internet service provider" },
        { translate: "keyword", key: "keyword" },
        { translate: "modem", key: "modem" },
        { translate: "network", key: "network" },
        { translate: "password", key: "password" },
        { translate: "preferences", key: "preferences" },
        { translate: "real time chat", key: "real time chat" },
        { translate: "to save", key: "to save" },
        { translate: "search engine", key: "search engine" },
        { translate: "spam", key: "spam" },
        { translate: "to surf", key: "to surf" },
        { translate: "sender", key: "sender" },
        { translate: "receiver", key: "receiver" },
        { translate: "to write text", key: "to write text" },
        { translate: "toolbar", key: "toolbar" },
        { translate: "username", key: "username" },
        { translate: "web address, url", key: "web address url" },
        { translate: "website", key: "website" },
    ],
    "measures and weights": [
        { translate: "millimeter", key: "millimeter" },
        { translate: "centimeter", key: "centimeter" },
        { translate: "meter", key: "meter" },
        { translate: "kilometer", key: "kilometer" },
        { translate: "milliliter", key: "milliliter" },
        { translate: "liter", key: "liter" },
        { translate: "gram", key: "gram" },
        { translate: "kilogram", key: "kilogram" },
        { translate: "ton", key: "ton" },
        { translate: "inch", key: "inch" },
        { translate: "foot", key: "foot" },
        { translate: "yard", key: "yard" },
        { translate: "mile", key: "mile" },
        { translate: "pint", key: "pint" },
        { translate: "quart", key: "quart" },
        { translate: "gallon", key: "gallon" },
        { translate: "ounce", key: "ounce" },
        { translate: "pound", key: "pound" },
    ],
    "containers and storage": [
        { translate: "bag, sack", key: "bag sack" },
        { translate: "barrel", key: "barrel" },
        { translate: "basket", key: "basket" },
        { translate: "bottle", key: "bottle" },
        { translate: "box", key: "box" },
        { translate: "bucket, pail", key: "bucket pail" },
        { translate: "can", key: "can" },
        { translate: "carton", key: "carton" },
        { translate: "case", key: "case" },
        { translate: "chest", key: "chest" },
        { translate: "container", key: "container" },
        { translate: "crate", key: "crate" },
        { translate: "envelope", key: "envelope" },
        { translate: "jar", key: "jar" },
        { translate: "jug", key: "jug" },
        { translate: "pack, packet", key: "pack packet" },
        { translate: "pitcher", key: "pitcher" },
        { translate: "tray", key: "tray" },
        { translate: "tub", key: "tub" },
        { translate: "tube", key: "tube" },
        { translate: "pocket", key: "pocket" },
    ],
}

export default activity
