import { withRouter } from "react-router-dom"
import React, { memo, useState } from "react"
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import activity from "./data/activity";
import archiveTemplate from "./data/archiveTemplate";

function App ({
    history,
    location,
}) {

    /* ACTIVITY PROPS */
    const [primaryList, setPrimaryList] = useState(activity);
    const activityProps = {
        primaryList: primaryList,
        setPrimaryList: setPrimaryList,
    }; /* END ACTIVITY PROPS */

    /* ARCHIVE PROPS */
    const [archive, setArchive] = useState(archiveTemplate);
    const archiveProps = {
        archive: archive,
        setArchive: setArchive,
    }; /* END ARCHIVE PROPS */

    /* AUTHENTICATION PROPS */
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    function handleEmail(e) { setEmail(e.currentTarget.value) }
    function handlePassword(e) { setPassword(e.currentTarget.value) }
    function handleSignOut() { setIsAuthenticated(false) }
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const user = await Auth.signIn(email, password);
            setIsAuthenticated(true);
            setEmail("");
            setPassword("");
            setUser(user);
            // history.goBack();
        } catch (e) {
            alert(e.message);
        }
    }
    const authProps = {
        isAuthenticated: isAuthenticated,
        userHasAuthenticated: false,
        handleSubmit: handleSubmit,
        handleSignOut: handleSignOut,
        handleEmail: handleEmail,
        handlePassword: handlePassword,
        email: email,
        password: password,
    }; /* END AUTHENTICATION PROPS */

    /* withROUTER PROPS */
    const routerProps = {
        goBack: history.goBack,
        push: history.push,
        pathname: location.pathname,
    }; /* END withROUTER PROPS */

    /* SETTINGS PROPS */
    const [open, setOpen] = useState(false);
    const [coordinates, setCoordinates] = useState(undefined);
    function settingsClickCallback(e) { setOpen(!open); setCoordinates({ x: e.clientX, y: e.clientY }); e.preventDefault(); }
    function onClose() { setOpen(false); }
    const settingsProps = {
        open: open,
        coordinates: coordinates,
        settingsClickCallback: settingsClickCallback,
        onClose: onClose,
    }; /* END SETTINGS PROPS */

    /* PREFERENCE PROPS */
    const [activeImage, setActiveImage] = useState(true);
    const [activeEnglishAudio, setActiveEnglishAudio] = useState(false);
    const [activeEnglishText, setActiveEnglishText] = useState(true);
    const [activeTargetAudio, setActiveTargetAudio] = useState(false);
    const [activeTargetText, setActiveTargetText] = useState(true);
    function toggleImage() { setActiveImage(!activeImage) }
    function toggleEnglishAudio() { setActiveEnglishAudio(!activeEnglishAudio) }
    function toggleEnglishText() { setActiveEnglishText(!activeEnglishText) }
    function toggleTargetAudio() { setActiveTargetAudio(!activeTargetAudio) }
    function toggleTargetText() { setActiveTargetText(!activeTargetText) }
    const preferenceProps = {
        activeImage: activeImage,
        activeEnglishAudio: activeEnglishAudio,
        activeEnglishText: activeEnglishText,
        activeTargetAudio: activeTargetAudio,
        activeTargetText: activeTargetText,
        toggleImage: toggleImage,
        toggleEnglishAudio: toggleEnglishAudio,
        toggleEnglishText: toggleEnglishText,
        toggleTargetAudio: toggleTargetAudio,
        toggleTargetText: toggleTargetText,
    }; /* END PREFERENCE PROPS */

    return (
        <>
            <Routes
                activityProps={activityProps}
                archiveProps={archiveProps}
                authProps={authProps}
                routerProps={routerProps}
                settingsProps={settingsProps}
                preferenceProps={preferenceProps}
            />
        </>
    )
};

export default withRouter(App);
