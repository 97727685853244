import { Switch, Route } from 'react-router-dom'
import React, { memo, Suspense, lazy } from 'react'

const Menu = lazy(() => import('./menu/index'))
const Activity = lazy(() => import('./activity/index'))
// const Authentication = lazy(() => import('./authentication/index'))

const routes = [
  { exact: true, path: "/", component: Menu },
  // { exact: true, path: "/auth", component: Authentication },
  { exact: true, path: "/Afrikaans", component: Menu },
  { exact: true, path: "/Afrikaans/Basics", component: Menu },
  { exact: true, path: "/Afrikaans/Basics/Class_Phrases", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Color", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Cycles_of_Time", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Days_of_The_Week", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Family", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Meals", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Months", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Numbers_One", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Numbers_Three", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Numbers_Two", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Periods_of_Time", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Seasons", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Social_Exchanges", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Time", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Time_of_The_Day", component: Activity },
  { exact: true, path: "/Afrikaans/Basics/Wind_Directions", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions", component: Menu },
  { exact: true, path: "/Afrikaans/Descriptions/Shapes", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Opposites", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Degrees_of_Comparison", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Feelings", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Moods", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Character_Traits", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Taste", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Adjectives_One", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Adjectives_Two", component: Activity },
  { exact: true, path: "/Afrikaans/Descriptions/Adverbs", component: Activity },
  { exact: true, path: "/Afrikaans/People", component: Menu },
  { exact: true, path: "/Afrikaans/People/Body_Internal", component: Activity },
  { exact: true, path: "/Afrikaans/People/Clothing_Fabrics", component: Activity },
  { exact: true, path: "/Afrikaans/People/Clothing_General", component: Activity },
  { exact: true, path: "/Afrikaans/People/Clothing_Mens", component: Activity },
  { exact: true, path: "/Afrikaans/People/Clothing_Womens", component: Activity },
  { exact: true, path: "/Afrikaans/People/Describe_Age", component: Activity },
  { exact: true, path: "/Afrikaans/People/Describe_Eyes_and_Skin", component: Activity },
  { exact: true, path: "/Afrikaans/People/Describe_Gender_and_Race", component: Activity },
  { exact: true, path: "/Afrikaans/People/Describe_Hair", component: Activity },
  { exact: true, path: "/Afrikaans/People/Describe_Weight_and_Height", component: Activity },
  { exact: true, path: "/Afrikaans/People/Exercises", component: Activity },
  { exact: true, path: "/Afrikaans/People/General_Physical_Descriptions", component: Activity },
  { exact: true, path: "/Afrikaans/People/Lower_Body_External", component: Activity },
  { exact: true, path: "/Afrikaans/People/Upper_Body_External", component: Activity },
  { exact: true, path: "/Afrikaans/Food", component: Menu },
  { exact: true, path: "/Afrikaans/Food/Beverages", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Dessert", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Eating_Out", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Food_Related_Actions", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Fruits", component: Activity },
  { exact: true, path: "/Afrikaans/Food/General_Food", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Grains_Nuts_and_Seeds", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Meats", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Seasonings_and_Condiments", component: Activity },
  { exact: true, path: "/Afrikaans/Food/Vegetables", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty", component: Menu },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Ailments_and_Symptoms", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Dentist", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Doctor", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/General_Health", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Hospital", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Illnesses", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Jewelry", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Physical_Examination", component: Activity },
  { exact: true, path: "/Afrikaans/Health_and_Beauty/Toiletries_and_Cosmetics", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling", component: Menu },
  { exact: true, path: "/Afrikaans/Dwelling/Appliances", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/Child_Care", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/Cooking_Ware", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/Dwelling", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/Furniture", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/House_Cleaning", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/House_Inside", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/House_Outside", component: Activity },
  { exact: true, path: "/Afrikaans/Dwelling/Parental_Phrases", component: Activity },
  { exact: true, path: "/Afrikaans/Urban_Life", component: Menu },
  { exact: true, path: "/Afrikaans/Urban_Life/Banking", component: Activity },
  { exact: true, path: "/Afrikaans/Urban_Life/City", component: Activity },
  { exact: true, path: "/Afrikaans/Urban_Life/Office_Supplies", component: Activity },
  { exact: true, path: "/Afrikaans/Urban_Life/Traffic", component: Activity },
  { exact: true, path: "/Afrikaans/Urban_Life/Travel", component: Activity },
  { exact: true, path: "/Afrikaans/Culture", component: Menu },
  { exact: true, path: "/Afrikaans/Culture/Art", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Courses", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Games", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Hobbies_and_Leisure", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Life_Events", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Music", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Religion", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/School", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Sports", component: Activity },
  { exact: true, path: "/Afrikaans/Culture/Vocations", component: Activity },
  { exact: true, path: "/Afrikaans/Mind", component: Menu },
  { exact: true, path: "/Afrikaans/Mind/Afrikaans_Intensive_Forms", component: Activity },
  { exact: true, path: "/Afrikaans/Mind/Emotions", component: Activity },
  { exact: true, path: "/Afrikaans/Mind/Intensive_Form", component: Activity },
  { exact: true, path: "/Afrikaans/Mind/Mental_States", component: Activity },
  { exact: true, path: "/Afrikaans/Mind/Think_Activities", component: Activity },
  { exact: true, path: "/Afrikaans/Animals", component: Menu },
  { exact: true, path: "/Afrikaans/Animals/Birds", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Baby_Animals", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Animal_Sounds", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Farm_Animals", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Fish_and_Aquatic_Life", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Human_Animal_Terms", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Insects", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Pets", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Reptiles_and_Amphibians", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Wild_Animals_Large", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Wild_Animals_Medium", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Wild_Animals_Small", component: Activity },
  { exact: true, path: "/Afrikaans/Animals/Zoo_Animals", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors", component: Menu },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Farm_Life", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Landscapes", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Metals_and_Elements", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Plants_and_Trees", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Universe_and_Planets", component: Activity },
  { exact: true, path: "/Afrikaans/Inanimate_Outdoors/Weather", component: Activity },
  { exact: true, path: "/Afrikaans/Geography", component: Menu },
  { exact: true, path: "/Afrikaans/Geography/Cities", component: Activity },
  { exact: true, path: "/Afrikaans/Geography/Continents", component: Activity },
  { exact: true, path: "/Afrikaans/Geography/Countries_and_Nationalities", component: Activity },
  { exact: true, path: "/Afrikaans/Geography/Languages", component: Activity },
  { exact: true, path: "/Afrikaans/Geography/Regions", component: Activity },
  { exact: true, path: "/Afrikaans/Communication", component: Menu },
  { exact: true, path: "/Afrikaans/Communication/Communication_Verbs", component: Activity },
  { exact: true, path: "/Afrikaans/Communication/Meetings", component: Activity },
  { exact: true, path: "/Afrikaans/Communication/Television", component: Activity },
  { exact: true, path: "/Afrikaans/Communication/Texting_Email", component: Activity },
  { exact: true, path: "/Afrikaans/Communication/The_Press", component: Activity },
  { exact: true, path: "/Afrikaans/Transportation", component: Menu },
  { exact: true, path: "/Afrikaans/Transportation/Transport_Bicycle", component: Activity },
  { exact: true, path: "/Afrikaans/Transportation/Transport_Car", component: Activity },
  { exact: true, path: "/Afrikaans/Transportation/Transport_Flight", component: Activity },
  { exact: true, path: "/Afrikaans/Transportation/Transport_Vehicles", component: Activity },
  { exact: true, path: "/Afrikaans/Tools", component: Menu },
  { exact: true, path: "/Afrikaans/Tools/Containers_and_Storage", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Digital_Computers", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Digital_Gadgets", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Digital_Internet", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Measures_and_Weights", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Tools_Garden", component: Activity },
  { exact: true, path: "/Afrikaans/Tools/Tools_General", component: Activity },
];

const Routes = memo(function ({
  activityProps,
  archiveProps,
  authProps,
  routerProps,
  settingsProps,
  preferenceProps,
}) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {
          routes.map(function ({ exact, path, component }, key) {
            return (
              <Route exact={exact} path={path} key={key}>
                {
                  component === Activity ? (
                    <Activity
                      {...activityProps}
                      {...archiveProps}
                      {...authProps}
                      {...routerProps}
                      {...settingsProps}
                      {...preferenceProps}
                    />
                  ) /*: component === Authentication ? (
                    <Authentication
                      {...authProps}
                      {...routerProps}
                    />
                  )*/ : component === Menu ? (
                    <Menu
                      {...authProps}
                      {...routerProps}
                    />
                  ) : null
                }
              </Route>
            )
          })
        }
      </Switch>
    </Suspense>
  )
})

export default Routes
