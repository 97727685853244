const archiveTemplate = {
    "Basics": {
        "Social Exchanges": [],
        "Class Phrases": [],
        "Color": [],
        "Wind Directions": [],
        "Seasons": [],
        "Months": [],
        "Days of the Week": [],
        "Periods of Time": [],
        "Cycles of Time": [],
        "Time of the Day": [],
        "Numbers One": [],
        "Numbers Two": [],
        "Numbers Three": [],
        "Time": [],
        "Familiy": [],
        "Meals": [],
    },
    "Descriptions": {
        "Shapes": [],
        "Opposites": [],
        "Degrees of Comparison": [],
        "Feelings": [],
        "Moods": [],
        "Character Traits": [],
        "Taste": [],
        "Adjectives One": [],
        "Adjectives Two": [],
        "Adverbs": [],
    },
    "People": {
        "Upper Body External": [],
        "Lower Body External": [],
        "Body Internal": [],
        "Exercises": [],
        "Clothing General": [],
        "Clothing Mens": [],
        "Clothing Womens": [],
        "Clothing Fabrics": [],
        "Describe Age": [],
        "Describe Gender and Race": [],
        "Describe Weight and Height": [],
        "Describe Hair": [],
        "Describe Eyes and Skin": [],
        "General Physical Descriptions": [],
    },
    "Food": {
        "General Foods": [],
        "Fruits": [],
        "Vegetables": [],
        "Meats": [],
        "Beverages": [],
        "Dessert": [],
        "Seasonings and Condiments": [],
        "Grains Nuts and Seeds": [],
        "Food Related Actions": [],
        "Eating Out": [],
    },
    "Health and Beauty": {
        "Jewelry": [],
        "Toiletries and Cosmetics": [],
        "General Health": [],
        "Ailments and Symptoms": [],
        "Illnesses": [],
        "Doctor": [],
        "Hospital": [],
        "Physical Examination": [],
        "Dentist": [],
    },
    "Dwelling": {
        "Dwelling": [],
        "House Outside": [],
        "House Inside": [],
        "Furniture": [],
        "Appliances": [],
        "Cooking Ware": [],
        "House Cleaning": [],
        "Parental Phrases": [],
        "Child Care": [],
    },
    "Urban Life": {
        "City": [],
        "Traffic": [],
        "Banking": [],
        "Office Supplies": [],
        "Travel": [],
    },
    "Culture": {
        "Art": [],
        "Music": [],
        "Religion": [],
        "Sports": [],
        "Games": [],
        "Hobbies and Leisure": [],
        "Life Events": [],
        "School": [],
        "Courses": [],
        "Vocations": [],
    },
    "Mind": {
        "Intensive Form": [],
        "Afrikaans Intensive Forms": [],
        "Think Activities": [],
        "Mental States": [],
        "Emotions": [],
    },
    "Animals": {
        "Wild Animals Large": [],
        "Wild Animals Medium": [],
        "Wild Animals Small": [],
        "Birds": [],
        "Insects": [],
        "Fish and Acquatic Life": [],
        "Reptiles and Amphibians": [],
        "Baby Animals": [],
        "Animal Sounds": [],
        "Human Animal Terms": [],
        "Pets": [],
        "Farm Animals": [],
        "Zoo Animals": [],
    },
    "Inanimate Outdoors": {
        "Universe and Planets": [],
        "Landscapes": [],
        "Farm Life": [],
        "Metals and Elements": [],
        "Plants and Trees": [],
        "Weather": [],
    },
    "Geography": {
        "Continents": [],
        "Regions": [],
        "Countries and Nationalities": [],
        "Languages": [],
        "Cities": [],
    },
    "Communication": {
        "Texting Email": [],
        "Television": [],
        "The Press": [],
        "Meetings": [],
        "Communication Verbs": [],
    },
    "Transportation": {
        "Transport Flight": [],
        "Transport Vehicles": [],
        "Transport Car": [],
        "Transport Bicycle": [],
    },
    "Tools": {
        "Tools Gengeral": [],
        "Tools Garden": [],
        "Digital Computers": [],
        "Digital Gadgets": [],
        "Digital Internet": [],
        "Measures and Weights": [],
        "Containers and Storage": [],
    }
};

export default archiveTemplate;
